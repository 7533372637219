import { formatStrictNameString } from "@features/config";
import { createApi } from "@reduxjs/toolkit/query/react";
import { HYDRATE } from "next-redux-wrapper";
import { baseQueries } from "@features/baseQueries";
import { IFiltersResponce } from "@features/types";
import { IDashboardsRequest, IDashboardsResponce } from "@features/types";

const { fetchBaseQueryWithRefresh } = baseQueries;

export const dashboardsApi = createApi({
  reducerPath: "dashboardsApi",
  baseQuery: fetchBaseQueryWithRefresh,
  extractRehydrationInfo(action, { reducerPath }) {
    if (action.type === HYDRATE) {
      return action.payload[reducerPath];
    }
  },
  endpoints: (builder) => ({
    getDashboards: builder.query<
      Array<IDashboardsResponce>,
      IDashboardsRequest
    >({
      query(credentials) {
        return `/api/dashboards/standart/${formatStrictNameString({
          ...credentials,
        })}`;
      },
    }),
    getFilters: builder.query<IFiltersResponce, string>({
      query(agent_uuid) {
        return `/api/agents/${agent_uuid}/dashboards/filters/`;
      },
    }),
  }),
});

export const { useGetDashboardsQuery, useGetFiltersQuery } = dashboardsApi;
