import styled from "styled-components";

export interface IRow {
  gap?: number;
  between?: boolean;
  center?: boolean;
  fullWidth?: boolean;
  alignItems?: string;
  justifyContent?: string;
  padding?: string;
  margin?: string;
  fullHeight?: boolean;
  grab?: boolean;
  width?: string;
}

export const Row = styled.div<IRow>`
  display: flex;
  flex-direction: row;
  align-items: ${({ alignItems }) => alignItems || "center"};
  width: ${({ fullWidth, width }) =>
    fullWidth ? "100%" : width ? width : "initial"};
  height: ${({ fullHeight }) => (fullHeight ? "100%" : "initial")};
  gap: ${({ gap }) => (gap ? `${gap}px` : 0)};
  margin: ${({ margin }) => margin || 0};
  justify-content: ${({ justifyContent, between, center }) =>
    justifyContent ||
    (between ? "space-between" : center ? "center" : "initial")};
  padding: ${({ padding }) => padding || 0};
  cursor: ${({ grab }) => (grab ? "grab" : "inherit")} !important;
`;
