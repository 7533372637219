import { IFiltersResponce } from "@features/types";
import { filtersDictionary } from "@constants";

function getKeyByValue(object: object, value: any) {
  return Object.keys(object).find(
    (key) => object[key as keyof typeof object] === value
  );
}

export const compareLengthFilterCollections = ({
  selected,
  all,
  field,
  isKey,
}: {
  selected: IFiltersResponce;
  all: IFiltersResponce | undefined;
  field: string;
  isKey: boolean;
}): boolean => {
  const comparedField = isKey
    ? field
    : getKeyByValue(filtersDictionary, field) || field;

  const lengthSelected = comparedField && selected[comparedField]?.length;
  const lengthAll = comparedField && all && all[comparedField]?.length - 1;
  return (
    typeof lengthSelected === "number" &&
    typeof lengthAll === "number" &&
    lengthSelected === lengthAll
  );
};
