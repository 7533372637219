import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import updateLocale from "dayjs/plugin/updateLocale";
import advancedFormat from "dayjs/plugin/advancedFormat";
import CommonTypes from "./commonTypes";
import { FORCE_RUSSIFICATION, RolesEnum } from "@constants";
import { IFiltersResponce, getAgentEnterInterface } from "@features/types";
import omit from "lodash/omit";

dayjs.extend(updateLocale);
dayjs.extend(isBetween);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

export const getFiltersByAgentType = (
  agent: getAgentEnterInterface | undefined,
  filterData: IFiltersResponce | undefined
): IFiltersResponce | undefined => {
  if (!agent || !filterData) {
    return;
  }
  return agent.agent_type === "MIXED"
    ? filterData
    : omit(filterData, ["call_direction"]);
};

export const pathFormatter: (path: string, query?: any) => string = (
  path,
  query
) => {
  const paths = {
    auth: "Авторизация",
    "": "Главная",
    agents: "Агенты",
    support: "Помощь",
    settings: "Настройки",
    "[agentPage]": query?.agentPage,
  };
  return paths[path as keyof typeof paths];
};

export const isFutureMonthYear = (month: number, year: number): boolean => {
  const currentDate = dayjs();
  const selectedDate = dayjs()
    .year(year)
    .month(month - 1);

  return selectedDate.isAfter(currentDate, "month");
};

export const tableRowFormatter = (height: number) => {
  if (height < 1200) {
    return 9;
  } else if (height > 1200 && height < 1400) {
    return 13;
  } else if (height > 1400 && height < 1800) {
    return 16;
  } else if (height > 1800) {
    return 22;
  } else {
    return 9;
  }
};

export function isSuccessResult(
  res: CommonTypes.resType
): res is CommonTypes.Success {
  if ("data" in res) {
    return true;
  }
  return false;
}

export const addProxy = (imgSrc: string) => {
  const host = window.location.origin;
  const updateUrl = imgSrc.replace(
    "https://storage.yandexcloud.net/smartdialogs.net/avatars/",
    `${host}/avatars/`
  );
  return updateUrl;
};

export function isValidRole(role: string): role is RolesEnum {
  return role in RolesEnum;
}

export const stringsAreNotEqual = (a: any, b: any) =>
  typeof a === "string" && typeof b === "string" && a !== b;

type TUpdateSortedFilters = ({
  originalFilters,
  selectedFilters,
}: {
  originalFilters: IFiltersResponce | undefined;
  selectedFilters: { [key: string]: (string | number | boolean)[] };
}) => IFiltersResponce | undefined;

export const updateSortedFilters: TUpdateSortedFilters = ({
  originalFilters,
  selectedFilters,
}) => {
  if (!originalFilters) {
    return;
  }

  const mergedFilters = { ...originalFilters };

  Object.keys(selectedFilters).forEach((key) => {
    if (originalFilters[key]) {
      const selectedValues = [...selectedFilters[key]].sort();
      const originalValues = originalFilters[key];

      // Сортируем значения в соответствии с порядком в selectedValues
      const orderedValues = [
        ...selectedValues.sort((a, b) => {
          const indexA = selectedValues.indexOf(a);
          const indexB = selectedValues.indexOf(b);
          return indexA - indexB;
        }),
        ...originalValues.filter((value) => !selectedValues.includes(value)),
      ];

      mergedFilters[key] = orderedValues;
    }
  });

  return mergedFilters;
};

export const forceTranslateString = (
  key: string,
  isForceTranslate: boolean
) => {
  if (!isForceTranslate) return key;
  if (key === "inbound") {
    return FORCE_RUSSIFICATION.agentTypeIn["rus"];
  }
  if (key === "outbound") {
    return FORCE_RUSSIFICATION.agentTypeOut["rus"];
  }
  if (key === "true") {
    return FORCE_RUSSIFICATION.booleanTrue["true"];
  }
  if (key === "false") {
    return FORCE_RUSSIFICATION.booleanFalse["true"];
  }
  return key;
};
