import React, { useState } from "react";
import { ButtonStyles } from "./styles";
import { IButton } from "./types";
import { Flex, ButtonIconSize, IconComponent } from "@new-ui-kit";
import { useTouchQuery } from "@helpers/hooks/apaptive";

const { Container } = ButtonStyles;

export const Button: React.FC<IButton> = ({
  pattern = "primary",
  size = "normal",
  fullWidth = false,
  hidden = false,
  height,
  icon = null,
  iconRight = false,
  children,
  loading = false,
  disabled = false,
  onClick = () => {},
  type,
  nowrap,
  isNowrapSpan = false,
  margin = null,
  selected = false,
  iconSize: _iconSize,
  isBorderTop,
  isBorderBottom,
}) => {
  const isSidebar = pattern === "sidebar";
  const isDropdown = pattern === "dropdown";
  const props = {
    pattern,
    size,
    hidden,
    height,
    fullWidth,
    iconRight,
    onClick,
    type,
    margin,
    icon,
    nowrap,
    isNowrapSpan,
    isSidebar,
    isDropdown,
    selected,
    isBorderTop,
    isBorderBottom,
  };
  const iconSize = ButtonIconSize[size];
  const [hovered, setHovered] = useState(false);
  const isTouchDevice = useTouchQuery();
  if (pattern === "link" || pattern === "deleteSecondary") {
    return (
      <Flex>
        {icon && (
          <IconComponent
            hovered={hovered}
            selected={selected}
            isSidebar={isSidebar}
            Icon={icon}
            height={iconSize}
            width={iconSize}
            pointer
          />
        )}
        <Container
          {...props}
          disabled={loading || disabled}
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
        >
          {children}
        </Container>
      </Flex>
    );
  }
  return (
    <Container
      {...props}
      disabled={loading || disabled}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {icon && (
        <IconComponent
          hovered={isTouchDevice ? undefined : hovered}
          selected={selected}
          isSidebar={isSidebar}
          Icon={icon}
          height={iconSize}
          width={iconSize}
          pointer
        />
      )}
      {children}
    </Container>
  );
};
