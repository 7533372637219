import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { userApi } from "./user-api-slice";
import type { RootState } from "@store";
import {
  AvatarMeta,
  GetFullUserDataInterface,
  GetUserResponseInterface,
} from "../types";
import { RolesEnum } from "@constants";
import { getCroppedImg } from "@components/Modals/SelectAvatar/components/SecondScreen/canvasUtils";
import { addProxy } from "@utils";

const initialProfileState: GetFullUserDataInterface = {
  id: null,
  avatar: null,
  avatar_meta: null,
  timezone: "",
  email: "",
  first_name: "",
  last_name: "",
  is_staff: false,
  city: null,
  city_information: {
    id: 0,
    name: "",
    country: 0,
  },
  isAuth: false,
  country: null,
  country_information: null,
  account_type: RolesEnum.BASE_PRIVILEGES,
  company: null,
  is_company_manager: false,
  croppedAvatar: null,
  hasAvatarError: false,
  isFirstAttemptForAvatarRefetch: true,
  company_currency: "RUB",
  force_translate: false,
};

export const setCroppedAvatar = createAsyncThunk(
  "profile/setCroppedAvatar",
  async (_, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const avatar = state.user.avatar;
    const avatarMeta = state.user.avatar_meta;
    if (!avatar || !avatarMeta) {
      return null;
    }

    const result = await getCroppedImg(addProxy(avatar), avatarMeta, 1);
    return result;
  }
);

const slice = createSlice({
  name: "profile",
  initialState: initialProfileState,
  reducers: {
    clearProfile: () => {
      return initialProfileState;
    },
    setProfile: (
      state,
      {
        payload: {
          id,
          timezone,
          email,
          first_name,
          last_name,
          is_staff,
          city,
          city_information,
          country,
          country_information,
          company,
          is_company_manager,
          company_currency,
          force_translate,
        },
      }: PayloadAction<GetUserResponseInterface>
    ) => {
      if (id) state.id = id;
      if (timezone) state.timezone = timezone;
      if (email) state.email = email;
      if (first_name) state.first_name = first_name;
      if (last_name) state.last_name = last_name;
      if (is_staff) state.is_staff = is_staff;
      if (city) state.city = city;
      if (city_information) state.city_information = city_information;
      if (country) state.country = country;
      if (country_information) state.country_information = country_information;
      if (company) state.company = company;
      if (is_company_manager) state.is_company_manager = is_company_manager;
      if (company_currency) state.company_currency = company_currency;
      if (force_translate) state.force_translate = force_translate;
    },
    setAuth: (
      state,
      { payload: { isAuth } }: PayloadAction<{ isAuth: boolean }>
    ) => {
      state.isAuth = isAuth;
    },
    setAvatar: (
      state,
      { payload: { avatar } }: PayloadAction<{ avatar: string | null }>
    ) => {
      state.avatar = avatar;
    },
    setAvatarMetadata: (state, { payload }: PayloadAction<AvatarMeta>) => {
      state.avatar_meta = payload;
    },
    setAvatarError: (state, { payload }: PayloadAction<boolean>) => {
      state.hasAvatarError = payload;
    },
    setFirstAttempt: (state, { payload }: PayloadAction<boolean>) => {
      state.isFirstAttemptForAvatarRefetch = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setCroppedAvatar.fulfilled, (state, action) => {
      state.croppedAvatar = action.payload;
    });
    builder.addCase(setCroppedAvatar.pending, (state, action) => {});
    builder.addCase(setCroppedAvatar.rejected, (state, action) => {
      state.hasAvatarError = true;
    });
    builder.addMatcher(
      userApi.endpoints.me.matchFulfilled,
      (state, { payload }) => {
        return {
          ...payload,
          isAuth: state?.isAuth,
          avatar: state?.avatar,
          isFirstAttemptForAvatarRefetch: state?.isFirstAttemptForAvatarRefetch,
        };
      }
    );
  },
});

export default slice.reducer;

export const {
  clearProfile,
  setProfile,
  setAuth,
  setAvatar,
  setAvatarMetadata,
  setAvatarError,
  setFirstAttempt,
} = slice.actions;

export const selectProfile = (state: RootState) => state.user;
export const selectCurrentUser = (state: RootState) => state.user.first_name;
export const selectAuth = (state: RootState) => state.user.isAuth;
export const selectCompanyManager = (state: RootState) =>
  state.user.is_company_manager;
export const selectAvatarMetadata = (state: RootState) =>
  state.user.avatar_meta;
export const selectCroppedAvatar = (state: RootState) =>
  state.user.croppedAvatar;
export const selectHasAvatarError = (state: RootState) =>
  state.user.hasAvatarError;
export const selectCompanyCurrency = (state: RootState) =>
  state.user.company_currency;
export const selectIsForceTranslate = (state: RootState) =>
  state.user.force_translate;
