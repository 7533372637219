import { NextPage } from "next";
import { Skeleton } from "@mui/material";
import { StyledSkeletonPropsInterface } from "./types";

export const StyledSkeleton: NextPage<StyledSkeletonPropsInterface> = ({
  ...props
}) => (
  <Skeleton
    animation="wave"
    sx={{
      bgcolor: "#f4f4f4",
      borderRadius: "24px",
    }}
    {...props}
  />
);
