import { formatStrictNameString } from "@features/config";
import { createApi } from "@reduxjs/toolkit/query/react";
import { HYDRATE } from "next-redux-wrapper";
import { baseQueries } from "@features/baseQueries";
import {
  GetFullUserDataInterface,
  EditMeRequestInterface,
  ResetPasswordRequestInterface,
  ResetPasswordConfirmRequestInterface,
  SetPasswordRequestInterface,
  CitiesParametersInterface,
  CountriesParametersInterface,
  TimezonesRequestInterface,
  ICity,
  ICountry,
  UserInfoInterface,
} from "@features/types";

const { baseQuery, fetchBaseQueryWithRefresh } = baseQueries;
export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: fetchBaseQueryWithRefresh,
  extractRehydrationInfo(action, { reducerPath }) {
    if (action.type === HYDRATE) return action.payload[reducerPath];
  },
  endpoints: (builder) => ({
    me: builder.query<GetFullUserDataInterface, {}>({
      query() {
        return "/api/users/me/";
      },
    }),

    editMe: builder.mutation<EditMeRequestInterface, UserInfoInterface>({
      query: (body) => ({
        url: "/api/users/me/",
        method: "PATCH",
        body,
      }),
    }),

    setPassword: builder.mutation<
      SetPasswordRequestInterface,
      SetPasswordRequestInterface
    >({
      query: (body) => ({
        url: "/api/users/set_password/",
        method: "POST",
        body,
      }),
    }),

    getTimezones: builder.query<TimezonesRequestInterface, void>({
      query() {
        return "/api/timezones/";
      },
    }),
    getCountries: builder.query<Array<ICountry>, CountriesParametersInterface>({
      query({ search }) {
        return `/api/countries/${formatStrictNameString({ search })}`;
      },
    }),
    getCities: builder.query<ICity[], CitiesParametersInterface>({
      query({ country, search }) {
        return `/api/cities/${formatStrictNameString({ country, search })}`;
      },
    }),
  }),
});

export const resetPasswordApi = createApi({
  reducerPath: "resetPasswordApi",
  baseQuery: baseQuery,
  extractRehydrationInfo(action, { reducerPath }) {
    if (action.type === HYDRATE) {
      return action.payload[reducerPath];
    }
  },
  endpoints: (builder) => ({
    resetPassword: builder.mutation<
      ResetPasswordRequestInterface,
      ResetPasswordRequestInterface
    >({
      query: (body) => ({
        url: "/api/users/reset_password/",
        method: "POST",
        body,
      }),
    }),
    resetPasswordConfirm: builder.mutation<
      ResetPasswordConfirmRequestInterface,
      ResetPasswordConfirmRequestInterface
    >({
      query: (body) => ({
        url: "/api/users/reset_password_confirm/",
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useMeQuery,
  useEditMeMutation,
  useSetPasswordMutation,
  useGetCitiesQuery,
  useGetTimezonesQuery,
  useGetCountriesQuery,
} = userApi;

export const { useResetPasswordMutation, useResetPasswordConfirmMutation } =
  resetPasswordApi;
