import styled, { css } from "styled-components";
import { IInputLabel, IInputOuter, IStyledInput } from "./types";

export const InputOuter = styled.div<IInputOuter>`
  cursor: ${({ isLabelInput }) => (isLabelInput ? "grab" : "pointer")};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: ${({ fullWidth, customWidthOuter }) =>
    fullWidth ? "100%" : customWidthOuter ? customWidthOuter : "264px"};
  height: ${({ isLabelInput }) => (isLabelInput ? "40px" : "48px")};
  padding: ${({ isLabel }) =>
    isLabel ? "4px 16px 8px 16px" : "4px 16px 4px 16px"};
  border: 1px solid
    ${({ theme, typeError }) =>
      typeError === "warning"
        ? "#FFE6B5"
        : typeError === "error"
        ? "#FF9696"
        : theme.form.default.border};
  border-radius: 10px;
  transition: 0.2s ease-out;
  background-color: ${({ theme }) => theme.form.default.bg};
  z-index: ${({ zIndex }) => (zIndex ? zIndex : "auto")};

  &:hover {
    border: 1px solid
      ${({ theme, disabled }) =>
        disabled ? theme.form.disabled.border : theme.form.hover.border};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      border: 1px solid ${({ theme }) => theme.form.disabled.border};
    `}

  ${({ focused }) =>
    focused &&
    css`
      border: 1px solid ${({ theme }) => theme.form.hover.border};
      box-shadow: 0px 0px 5px rgba(79, 70, 229, 0.5);
    `}
  
  overflow-x: scroll;
  overflow-y: hidden;
  &::-webkit-scrollbar {
    height: 0px;
    width: 0px;
  }
  ${({ isSearchDropdown }) =>
    isSearchDropdown &&
    css`
      border: none;
      box-shadow: none;
      &:hover {
        border: none;
        border-radius: 0px;
        border-bottom: 1px solid #e4e4e7;
      }
      ${(focused) =>
        focused &&
        css`
          border: none;
          box-shadow: none;
          border-radius: 0px;
          border-bottom: 1px solid #e4e4e7;
        `}
      border-radius: 0px;
      border-bottom: 1px solid #e4e4e7;
      padding: 0;
    `}
  ${({ showDropdown, bottomTransform }) =>
    bottomTransform &&
    showDropdown &&
    css`
      border-bottom: none;
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
    `}
  ${({ bottomTransform }) =>
    bottomTransform &&
    css`
      margin-bottom: 0;
    `}
    @media (max-width: 1281px) {
    width: ${({ fullWidth, customWidthOuter }) =>
      fullWidth ? "100%" : customWidthOuter ? customWidthOuter : "238px"};
  }
`;

export const StyledInput = styled.input<IStyledInput>`
  font-weight: 400;
  font-size: 16px;
  background-color: ${({ theme }) => theme.form.default.bg};
  color: ${({ theme }) => theme.form.default.text_dark};
  min-width: 40px;
  width: ${({ customWidth, fullWidthInput }) =>
    fullWidthInput ? "max-content" : customWidth ? customWidth : "100%"};
  padding: ${({ padding }) => (padding ? padding : 0)};
  &:-webkit-autofill,
  &:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
    -webkit-box-shadow: 0 0 0px 1000px inherit inset;
  }
  &:disabled {
    background-color: ${({ theme }) => theme.form.default.bg};
    color: ${({ theme }) => theme.form.disabled.text};
  }
  &:disabled::placeholder {
    color: ${({ theme }) => theme.form.disabled.text};
  }
  ${({ pointer }) =>
    pointer &&
    css`
      cursor: pointer;
    `}
`;

export const Label = styled.div<IInputLabel>`
  display: flex;
  flex-direction: row;
  justify-items: center;
  align-items: center;
  font-size: 11px;
  color: ${({ theme, disabled }) =>
    disabled ? theme.form.disabled.text : theme.form.default.text};
`;
