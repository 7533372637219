import { NextPage } from "next";

import WarningIcon from "@assets/inputIcons/warning.svg";
import SuccessIcon from "@assets/inputIcons/success.svg";
import ErrorIcon from "@assets/inputIcons/error.svg";
import { DescriptionPropsInterface } from "./types";
import { InputStyles } from "../../styles";

const { Description, DescriptionTitle } = InputStyles;

const InputDescription: NextPage<DescriptionPropsInterface> = ({
  name,
  errors,
  isSuccessDescription,
  successDescription,
  defaultDescription,
}) => {
  const isSuccess = isSuccessDescription || false;
  const isError = !!name && !!errors && !!errors[name];

  const typeError = isError && errors[name]?.message?.typeError;
  const errorMessage = isError && errors[name]?.message?.message;

  const isSuccessDescr = successDescription && isSuccess;
  const isDescription =
    !!defaultDescription || !!errorMessage || !!isSuccessDescr;
  const description = isSuccess
    ? successDescription
    : errorMessage
    ? errorMessage
    : defaultDescription
    ? defaultDescription
    : null;

  return (
    <Description isDescription={isDescription}>
      {errorMessage && typeError === "warning" ? (
        <WarningIcon />
      ) : errorMessage && typeError === "error" ? (
        <ErrorIcon />
      ) : isSuccess ? (
        <SuccessIcon />
      ) : null}
      {isDescription && <DescriptionTitle>{description}</DescriptionTitle>}
    </Description>
  );
};

export default InputDescription;
