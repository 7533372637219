export enum ButtonPadding {
  normal = "12px 24px",
  medium = "8px 24px",
  small = "4px 16px",
  extraSmall = "4px 16px",
}

export enum ButtonTextSize {
  normal = 16,
  medium = 16,
  small = 14,
  extraSmall = 12,
}

export enum ButtonIconSize {
  normal = 12,
  medium = 12,
  small = 9,
  extraSmall = 9,
}

export enum TextSize {
  loginHeadline = 28,
  headline1 = 24,
  headline2 = 20,
  headline3 = 18,
  headline4 = 16,
  body1 = 16,
  body2 = 14,
  caption1 = 12,
  caption2 = 11,
  bottomSheet = 16,
}

export enum TextSizeTablet {
  headline1 = 24,
  loginHeadline = 28,
  headline2 = 20,
  headline3 = 18,
  headline4 = 16,
  body1 = 16,
  body2 = 14,
  caption1 = 12,
  caption2 = 11,
  bottomSheet = 16,
}

export enum TextSizeMobile {
  headline1 = 20,
  loginHeadline = 28,
  headline2 = 16,
  headline3 = 16,
  headline4 = 16,
  body1 = 16,
  body2 = 14,
  caption1 = 12,
  caption2 = 11,
  bottomSheet = 16,
}

export enum TextWeight {
  headline1 = 700,
  headline2 = 500,
  loginHeadline = 500,
  headline3 = 700,
  headline4 = 500,
  body1 = 400,
  body2 = 400,
  caption1 = 400,
  caption2 = 400,
  bottomSheet = 700,
}

export enum TextWeightTablet {
  headline1 = 600,
  headline2 = 500,
  loginHeadline = 400,
  headline3 = 700,
  headline4 = 500,
  body1 = 400,
  body2 = 400,
  caption1 = 400,
  caption2 = 400,
  bottomSheet = 500,
}

export enum TextWeightMobile {
  headline1 = 600,
  headline2 = 700,
  loginHeadline = 500,
  headline3 = 500,
  headline4 = 500,
  body1 = 400,
  body2 = 400,
  caption1 = 400,
  caption2 = 400,
  bottomSheet = 500,
}

export enum TextLineHeight {
  headline1 = "140%",
  headline2 = "140%",
  loginHeadline = "142%",
  headline3 = "140%",
  headline4 = "140%",
  body1 = "24px",
  body2 = "20px",
  caption1 = "16px",
  caption2 = "16px",
  bottomSheet = "24px",
}
