import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { authApi } from "./auth-api-slice";
import type { RootState } from "@store";
import { UserResponse, TUserRole, FullUserResponse } from "../types";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";

export interface IAuthState extends FullUserResponse {
  tokenError?: unknown | null;
}

const initialState: IAuthState = {
  refresh: null,
  access: null,
  tokenError: null,
  role: null,
};

const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setCredentials: (
      state,
      { payload: { refresh, access, role } }: PayloadAction<UserResponse>
    ) => {
      if (refresh) state.refresh = refresh;
      if (access) state.access = access;
      if (role) state.role = role;
    },
    setAccessToken: (
      state,
      { payload: { access } }: PayloadAction<{ access: string }>
    ) => {
      if (access) {
        state.access = access;
        state.tokenError = null;
      }
    },
    setUserRole: (
      state,
      { payload: { role } }: PayloadAction<{ role: TUserRole }>
    ) => {
      if (role) state.role = role;
    },
    resetTokenError: (state) => {
      state.tokenError = null;
    },
    setTokenError: (
      state,
      { payload }: PayloadAction<FetchBaseQueryError | undefined>
    ) => {
      state.tokenError = payload?.data;
    },
  },

  extraReducers: (builder) => {
    builder.addMatcher(
      authApi.endpoints.login.matchFulfilled,
      (state, { payload }) => {
        if (payload.refresh) {
          state.refresh = payload.refresh;
        }
        if (payload.access) {
          state.access = payload.access;
        }
        state.tokenError = null;
      }
    );
    builder.addMatcher(
      authApi.endpoints.refresh.matchFulfilled,
      (state, { payload }) => {
        if (payload.refresh) {
          state.refresh = payload.refresh;
        }
        if (payload.access) {
          state.access = payload.access;
        }
        state.tokenError = null;
      }
    );
    builder.addMatcher(
      authApi.endpoints.refresh.matchRejected,
      (state, error) => {
        state.tokenError = error.payload?.data;
      }
    );
    builder.addMatcher(
      authApi.endpoints.checkToken.matchRejected,
      (state, error) => {
        if (
          error.payload &&
          "status" in error.payload &&
          error.payload.status === 401
        )
          state.tokenError = error.payload?.data;
      }
    );
  },
});
export const { setCredentials, setAccessToken, setUserRole, setTokenError } =
  slice.actions;

export default slice.reducer;

export const selectAccessToken = (state: RootState) => state.auth.access;
export const selectRefreshToken = (state: RootState) => state.auth.refresh;
export const selectUserRole = (state: RootState) => state.auth.role;
export const selectTokenError = (state: RootState) => state.auth.tokenError;
