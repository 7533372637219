import { HYDRATE } from "next-redux-wrapper";
import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueries } from "@features/baseQueries";
import {
  AddLabelRequestInterface as AddLabelsRequest,
  EditLabelRequestInterface as EditLabelRequest,
  GetLabelsResponceInterface as GetLabelsResponce,
  LabelsInterface,
} from "@features/types";

const { fetchBaseQueryWithRefresh } = baseQueries;

export const labelsApi = createApi({
  reducerPath: "labelsApi",
  baseQuery: fetchBaseQueryWithRefresh,
  extractRehydrationInfo(action, { reducerPath }) {
    if (action.type === HYDRATE) return action.payload[reducerPath];
  },
  endpoints: (builder) => ({
    getLabels: builder.query<GetLabelsResponce, {}>({
      query() {
        return "/api/labels/";
      },
    }),
    addLabel: builder.mutation<LabelsInterface, AddLabelsRequest>({
      query: (body) => ({
        url: "/api/labels/",
        method: "POST",
        body,
      }),
    }),
    editLabel: builder.mutation<LabelsInterface, EditLabelRequest>({
      query: (body) => {
        return {
          url: `/api/labels/${body.id}/`,
          method: "PATCH",
          body,
        };
      },
    }),
    deleteLabel: builder.mutation<void, LabelsInterface>({
      query: ({ id }) => {
        return {
          url: `/api/labels/${id}/`,
          method: "DELETE",
        };
      },
    }),
  }),
});

export const {
  useAddLabelMutation,
  useGetLabelsQuery,
  useEditLabelMutation,
  useDeleteLabelMutation,
} = labelsApi;
