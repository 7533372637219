import { ReactNode, useEffect, useState } from "react";
import { TooltipStyles } from "./styles";
import { Flex } from "@new-ui-kit";
import { TArrowDirection, TTooltipPosition, TTooltipType } from "./types";
import { useTouchQuery } from "@helpers/hooks/apaptive";

const { ToolTipComponentWrapper, FlexWrapper } = TooltipStyles;

export const Tooltip = ({
  showForce = false,
  children,
  endContent,
  showOnHover = true,
  title,
  type = "info",
  arrowDirection = "bottom",
  nowrap = true,
  position = "top",
  width = "fit-content",
  isSmallPaddings = false,
  id,
}: {
  showForce?: boolean;
  children: ReactNode;
  endContent?: ReactNode;
  showOnHover?: boolean;
  title: string;
  type?: TTooltipType;
  arrowDirection?: TArrowDirection;
  position?: TTooltipPosition;
  nowrap?: boolean;
  width?: string;
  isSmallPaddings?: boolean;
  id?: string;
}) => {
  const [show, setShow] = useState(false);
  const open = showForce || show;
  const isTouchDevice = useTouchQuery();

  const onMouseEnter = () => {
    if (showOnHover) {
      setShow(true);
    }
  };

  const onMouseLeave = () => {
    if (showOnHover) {
      setShow(false);
    }
  };

  const onClick = () => {
    if (!showOnHover || isTouchDevice) {
      setShow(true);
    }
  };

  useEffect(() => {
    if (!showOnHover || isTouchDevice) {
      setTimeout(() => {
        setShow(false);
      }, 3000);
    }
  }, [open]);

  return (
    <>
      <FlexWrapper id={id}>
        <Flex
          onMouseEnter={isTouchDevice ? undefined : onMouseEnter}
          onMouseLeave={isTouchDevice ? undefined : onMouseLeave}
          onClick={onClick}
        >
          {children}
        </Flex>

        {open && (
          <ToolTipComponentWrapper
            show={open}
            nowrap={nowrap}
            type={type}
            arrowDirection={arrowDirection}
            position={position}
            isArrow={type === "warning"}
            width={width}
            isSmallPaddings={isSmallPaddings}
          >
            <Flex flexDirection="row" alignItems="center">
              {title} {endContent ? endContent : null}
            </Flex>
          </ToolTipComponentWrapper>
        )}
      </FlexWrapper>
    </>
  );
};
