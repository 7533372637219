import styled, { css } from "styled-components";
import {
  InputContainerPropsInterface,
  StyledInputPropsInterface,
  StyledLabelPropsInterface,
} from "./types";
import breakpoints from "@styles/breakpoints";

const InputContainer = styled.div<InputContainerPropsInterface>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 10px;
  padding: 4px 16px;
  width: ${({ width, isChangeSize }) => (isChangeSize ? "fit-content" : width)};
  max-width: ${({ maxWidth }) => maxWidth};
  cursor: ${({ pointer }) => (pointer ? "pointer" : "inherit")};
  transition: background-color 0.2s ease-in-out;
  background-color: ${({ theme, state, coloredFilled }) =>
    state === "filled" && !coloredFilled
      ? theme.form.default.bg
      : theme.form[state].bg};
  border: 1px solid
    ${({ theme, state, coloredFilled }) =>
      state === "filled" && coloredFilled
        ? theme.form[state].bg
        : theme.form[state].border};
  box-shadow: ${({ theme, state }) => theme.form[state].shadow};
  height: 48px;
  min-height: 48px;
  ${({ noHover, coloredFilled, state }) =>
    !noHover &&
    css`
      &:hover {
        border: 1px solid
          ${({ theme }) =>
            !coloredFilled && state !== "disabled"
              ? theme.form.hover.border
              : theme.form[state].border};
      }
    `}
  @media ${breakpoints.mobile} {
    min-height: ${({ isAdaptiveHeight }) =>
      isAdaptiveHeight ? "40px" : "48px"};
    height: ${({ isAdaptiveHeight }) => (isAdaptiveHeight ? "40px" : "48px")};
  }
  ${({ unwrapBottom }) =>
    unwrapBottom &&
    css`
      -webkit-transition: height 0.2s ease-in-out;
      -moz-transition: height 0.2s ease-in-out;
      -ms-transition: height 0.2s ease-in-out;
      -o-transition: height 0.2s ease-in-out;
      transition: height 0.2s ease-in-out;

      -webkit-transition: max-height 0.2s ease-in-out;
      -moz-transition: max-height 0.2s ease-in-out;
      -ms-transition: max-height 0.2s ease-in-out;
      -o-transition: max-height 0.2s ease-in-out;
      transition: max-height 0.2s ease-in-out;
    `}
  -webkit-height: ${({ unwrapBottom }) =>
    unwrapBottom ? "max-content" : "48px"};
  -webkit-max-height: ${({ unwrapBottom }) =>
    unwrapBottom ? "352px" : "48px"};
  -webkit-padding: ${({ unwrapBottom }) => (unwrapBottom ? "0px" : "4px 16px")};

  -moz-height: ${({ unwrapBottom }) => (unwrapBottom ? "max-content" : "48px")};
  -moz-max-height: ${({ unwrapBottom }) => (unwrapBottom ? "352px" : "48px")};
  -moz-padding: ${({ unwrapBottom }) => (unwrapBottom ? "0px" : "4px 16px")};

  -ms-height: ${({ unwrapBottom }) => (unwrapBottom ? "max-content" : "48px")};
  -ms-max-height: ${({ unwrapBottom }) => (unwrapBottom ? "352px" : "48px")};
  -ms-padding: ${({ unwrapBottom }) => (unwrapBottom ? "0px" : "4px 16px")};

  -o-height: ${({ unwrapBottom }) => (unwrapBottom ? "max-content" : "48px")};
  -o-max-height: ${({ unwrapBottom }) => (unwrapBottom ? "352px" : "48px")};
  -o-padding: ${({ unwrapBottom }) => (unwrapBottom ? "0px" : "4px 16px")};

  height: ${({ unwrapBottom }) => (unwrapBottom ? "max-content" : "48px")};
  max-height: ${({ unwrapBottom }) => (unwrapBottom ? "352px" : "48px")};
  padding: ${({ unwrapBottom }) => (unwrapBottom ? "0px" : "4px 16px")};

  ${({ isSmallestInput }) =>
    isSmallestInput &&
    css`
      @media ${breakpoints.mobile} {
        height: 40px;
        min-height: 40px;
        padding: 4px 12px;
      }
    `}
`;

const StyledInput = styled.input<StyledInputPropsInterface>`
  background-image: none !important;
  font-style: normal;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  transition: background-color 0.2s ease-in-out;
  color: ${({ theme, state }) => theme.form[state].text_dark};
  background-color: ${({ theme, state, coloredFilled }) =>
    state === "filled" && !coloredFilled
      ? theme.form.default.bg
      : theme.form[state].bg};
  opacity: ${({ reducedLabel }) => (reducedLabel ? 1 : 0)};
  cursor: ${({ pointer }) => (pointer ? "pointer" : "text")};
  &:-webkit-autofill,
  &:-webkit-autofill:focus {
    /* -webkit-box-shadow: inset 0 0 0 50px white; цвет вашего фона */
    transition: background-color 600000s 0s, color 600000s 0s;
    -webkit-box-shadow: 0 0 0px 1000px inherit inset;
  }

  &:disabled {
    cursor: not-allowed;
  }

  ${({ isSmallestInput }) =>
    isSmallestInput &&
    css`
      @media ${breakpoints.mobile} {
        font-size: 11px;
        line-height: 16px;
      }
    `}
`;

const StyledLabel = styled.label<StyledLabelPropsInterface>`
  display: flex;
  position: relative;
  transition: 0.2s ease-out;
  align-items: center;
  font-weight: 400;
  white-space: nowrap;
  color: ${({ theme, state }) => theme.form[state].text};
  top: ${({ reducedLabel }) => (reducedLabel ? "0px" : "12px")};
  font-size: ${({ reducedLabel }) => (reducedLabel ? "11px" : "16px")};
  cursor: ${({ cursor }) => cursor};
  pointer-events: none;
  @media ${breakpoints.mobile} {
    top: ${({ reducedLabel, isSmallest }) =>
      reducedLabel ? "0px" : isSmallest ? "7px" : "12px"};
    font-size: ${({ reducedLabel, isSmallest }) =>
      reducedLabel ? "11px" : isSmallest ? "12px" : "16px"};
  }
`;

const Description = styled.div<{ isDescription: boolean }>`
  display: ${({ isDescription }) => (isDescription ? "flex" : "none")};
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  min-width: 100px;
  margin-top: ${({ isDescription }) => (isDescription ? "8px" : null)};
  line-height: 16px;
`;

const DescriptionTitle = styled.div`
  font-weight: 400;
  font-size: 12px;
  color: ${({ theme }) => theme.form.description};
`;

export const InputStyles = {
  StyledInput,
  InputContainer,
  StyledLabel,
  Description,
  DescriptionTitle,
};
