import { COMMON_BOX_SHADOW } from "@styles/GlobalStyle";
import styled from "styled-components";
import { TArrowDirection, TTooltipPosition, TTooltipType } from "./types";

const ToolTipComponentWrapper = styled.div<{
  show: boolean;
  arrowDirection: TArrowDirection;
  type: TTooltipType;
  nowrap: boolean;
  isArrow: boolean;
  position: TTooltipPosition;
  width: string;
  isSmallPaddings: boolean;
}>`
  position: absolute;
  pointer-events: none;
  background: ${({ type }) => (type === "info" ? "#484666" : "#f7b7cd")};
  white-space: ${({ nowrap }) => (nowrap ? "nowrap" : "normal")};
  color: ${({ theme, type }) =>
    type === "info" ? theme.white : theme.text.black};
  width: ${({ width }) => width};
  font-size: 11px;
  padding: ${({ isSmallPaddings }) =>
    isSmallPaddings ? "4px 8px" : "8px 12px"};

  line-height: 130%;
  font-weight: 400;
  -webkit-box-shadow: ${COMMON_BOX_SHADOW};
  box-shadow: ${COMMON_BOX_SHADOW};
  border-radius: ${({ type }) => (type === "info" ? "6px" : "8px")};
  border: 1px solid transparent;
  opacity: ${({ show }) => (show ? "1" : "0")};
  top: 0;
  left: 0;
  transform: ${({ position, isArrow }) =>
    position === "top" && !isArrow
      ? "translate(-45%, -100%)"
      : isArrow && position === "top"
      ? "translate(-45%, -125%)"
      : position === "bottom"
      ? "translate(-40%, 20px)"
      : position === "right" && isArrow
      ? "translate(0, -30%)"
      : position === "top-right" && !isArrow
      ? "translate(0px, -105%)"
      : position === "bottom-right"
      ? "translate(0px, 25px)"
      : position === "left" && !isArrow
      ? "translate(-100%, -30%)"
      : position === "left" && isArrow
      ? "translate(-110%, -30%)"
      : "none"};
  transition: 0.2s ease-in-out;
  z-index: ${({ show }) => (show ? 1000 : 0)};

  &:after {
    content: "";
    display: ${({ isArrow }) => (isArrow ? "block" : "none")};
    position: absolute;
    width: 10px;
    height: 10px;
    border-top-right-radius: 0px;
    border: 1px solid transparent;
    background-color: #f7b7cd;
    z-index: -10;
    bottom: ${({ arrowDirection }) =>
      arrowDirection === "bottom"
        ? "-5px"
        : arrowDirection === "left" || arrowDirection === "right"
        ? "40%"
        : "0px"};
    left: ${({ arrowDirection }) =>
      arrowDirection === "bottom"
        ? "50%"
        : arrowDirection === "left"
        ? "0px"
        : arrowDirection === "right"
        ? "100%"
        : "50%"};
    margin-left: -6px;
    transform: rotate(135deg);
  }
`;

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  opacity: 1;
`;

export const TooltipStyles = {
  FlexWrapper,
  ToolTipComponentWrapper,
};
