import { HYDRATE } from "next-redux-wrapper";
import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueries } from "@features/baseQueries";
import {
  ICreateTokenRequest,
  IDeleteTokenRequest,
  IGetTokensRequest,
  IToken,
  ITokenInfo,
  ITokenRequestCounter,
  IUpdateTokenRequest,
} from "@features/types";
import { formatStrictNameString } from "@features/config";

const { fetchBaseQueryWithRefresh } = baseQueries;

export const tokensApi = createApi({
  reducerPath: "tokensApi",
  baseQuery: fetchBaseQueryWithRefresh,
  extractRehydrationInfo(action, { reducerPath }) {
    if (action.type === HYDRATE) return action.payload[reducerPath];
  },
  endpoints: (builder) => ({
    getTokens: builder.query<Array<ITokenInfo>, IGetTokensRequest>({
      query({ ordering }) {
        return `/api/tokens/${formatStrictNameString({ ordering })}`;
      },
    }),

    createToken: builder.mutation<IToken, ICreateTokenRequest>({
      query: (body) => ({
        url: "/api/tokens/",
        method: "POST",
        body,
      }),
    }),

    updateToken: builder.mutation<ITokenInfo, IUpdateTokenRequest>({
      query: ({ id, ...body }) => {
        return {
          url: `/api/tokens/${id}/`,
          method: "PATCH",
          body,
        };
      },
    }),

    deleteToken: builder.mutation<void, IDeleteTokenRequest>({
      query: ({ id }) => {
        return {
          url: `/api/tokens/${id}/`,
          method: "DELETE",
        };
      },
    }),

    getRequestCounter: builder.query<ITokenRequestCounter, {}>({
      query() {
        return "/api/tokens/requests_counter/";
      },
    }),
  }),
});

export const {
  useCreateTokenMutation,
  useGetTokensQuery,
  useUpdateTokenMutation,
  useDeleteTokenMutation,
  useGetRequestCounterQuery,
} = tokensApi;
