import React, { ChangeEvent } from "react";
import { Text } from "@new-ui-kit";
import { Item, RadioButton, RadioButtonLabel, Wrapper, Circle } from "./styles";
import { IMenuItems, IRadioButtons } from "./types";

export const RadioButtons = ({
  values,
  handleChange,
  selectedValue,
  name,
  asButton,
  labelWidth,
  noPadding = false,
  asRadio = true,
  small = false,
  tips = false,
  row = false,
  disabled = false,
  noVerticalMargin = false,
  wrapperHeight,
}: IRadioButtons) => {
  const selectItem =
    (value: IMenuItems) => (e: React.MouseEvent<HTMLElement>) => {
      if (disabled || value.disabled) return;

      e.stopPropagation();
      handleChange(value.key);
    };
  return (
    <Wrapper
      labelWidth={labelWidth}
      small={small}
      row={row}
      noVerticalMargin={noVerticalMargin}
      wrapperHeight={wrapperHeight}
    >
      {values.map((value, index) => (
        <div key={value.value?.toString()}>
          <Item
            small={small}
            disabled={disabled || value.disabled}
            value={String(value.key)}
            inTableIndex={wrapperHeight ? value.index : null}
            asRadio={asRadio}
            noPadding={noPadding}
            checked={String(selectedValue) === String(value.key)}
            onClick={selectItem(value)}
          >
            <RadioButton
              asButton={asButton}
              labelWidth={labelWidth}
              disabled={disabled}
              small={small}
              type="radio"
              name={name}
              value={String(value.key)}
              checked={String(selectedValue) === String(value.key)}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                e.stopPropagation();
                handleChange(value.key);
              }}
            />
            <Circle />
            <RadioButtonLabel
              labelWidth={labelWidth}
              asButton={asButton}
              small={small}
              disabled={disabled}
            />
            <Text pointer>{value.value}</Text>
          </Item>
        </div>
      ))}
    </Wrapper>
  );
};
