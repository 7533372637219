import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQueries } from "@features/baseQueries";
import { HYDRATE } from "next-redux-wrapper";
import { IWidgetDataItem } from "@components/screens/agents/agentPage/components/dashboardPage/types";
import {
  DoughnutWidgetType,
  GetWidgetDataRequest,
  IChangeWidgetRequest,
  ICreateWidgetRequest,
  ICreateWidgetResponce,
  IDataRequest,
  IDeleteWidgetRequest,
  IEditWidgetRequest,
  IEditWidgetResponse,
  IGetBarWidgetDataResponse,
  IGetNumericWidgetDataResponse,
} from "@features/types";

const { fetchBaseQueryWithRefresh } = baseQueries;

export const widgetsApi = createApi({
  reducerPath: "widgetsApi",
  baseQuery: fetchBaseQueryWithRefresh,
  extractRehydrationInfo(action, { reducerPath }) {
    if (action.type === HYDRATE) {
      return action.payload[reducerPath];
    }
  },
  endpoints: (builder) => ({
    getWidgets: builder.query<Array<IWidgetDataItem>, IDataRequest>({
      query({ agent_uuid }) {
        return `/api/agents/${agent_uuid}/dashboards/`;
      },
    }),
    createWidget: builder.mutation<ICreateWidgetResponce, ICreateWidgetRequest>(
      {
        query: ({ agent_uuid, ...props }) => {
          return {
            url: `/api/agents/${agent_uuid}/dashboards/`,
            method: "POST",
            body: {
              ...props,
            },
          };
        },
      }
    ),
    changeWidget: builder.mutation<IEditWidgetResponse, IChangeWidgetRequest>({
      query: ({ agent_uuid, id, ...props }) => {
        return {
          url: `/api/agents/${agent_uuid}/dashboards/${id}/`,
          method: "PATCH",
          body: {
            ...props,
          },
        };
      },
    }),
    editWidget: builder.mutation<ICreateWidgetResponce, IEditWidgetRequest>({
      query: ({ agent_uuid, id, ...props }) => {
        return {
          url: `/api/agents/${agent_uuid}/dashboards/${id}/`,
          method: "PATCH",
          body: {
            ...props,
          },
        };
      },
    }),
    getNumericWidgetData: builder.query<
      IGetNumericWidgetDataResponse,
      GetWidgetDataRequest
    >({
      query({ agent_uuid, id, start, end }) {
        return `/api/agents/${agent_uuid}/dashboards/${id}/data/?start_date=${start}&end_date=${end}`;
      },
    }),
    getTimeWidgetData: builder.query<
      IGetNumericWidgetDataResponse,
      GetWidgetDataRequest
    >({
      query({ agent_uuid, id, start, end }) {
        return `/api/agents/${agent_uuid}/dashboards/${id}/data/?start_date=${start}&end_date=${end}`;
      },
    }),
    getBarWidgetData: builder.query<
      IGetBarWidgetDataResponse,
      GetWidgetDataRequest
    >({
      query({ agent_uuid, id, start, end }) {
        return `/api/agents/${agent_uuid}/dashboards/${id}/data/?start_date=${start}&end_date=${end}`;
      },
    }),
    getDoughnutData: builder.query<DoughnutWidgetType, GetWidgetDataRequest>({
      query({ agent_uuid, id, start, end }) {
        return `/api/agents/${agent_uuid}/dashboards/${id}/data/?start_date=${start}&end_date=${end}`;
      },
    }),
    deleteWidget: builder.mutation<void, IDeleteWidgetRequest>({
      query: ({ agent, id }) => {
        return {
          url: `/api/agents/${agent}/dashboards/${id}/`,
          method: "DELETE",
        };
      },
    }),
  }),
});

export const {
  useGetWidgetsQuery,
  useCreateWidgetMutation,
  useGetNumericWidgetDataQuery,
  useGetBarWidgetDataQuery,
  useChangeWidgetMutation,
  useGetTimeWidgetDataQuery,
  useGetDoughnutDataQuery,
  useEditWidgetMutation,
  useDeleteWidgetMutation,
} = widgetsApi;
