import { agentsApi } from "./agents-api-slice";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "@store";
import { Color, AgentsType } from "../types";

export const initialAgents: AgentsType = {
  colors: [],
  openedLabel: false,
  nextWidget: "start",
};

const slice = createSlice({
  name: "agents",
  initialState: initialAgents,
  reducers: {
    setColors: (
      state,
      { payload: { colors } }: PayloadAction<{ colors: Array<Color> }>
    ) => {
      state.colors = colors;
    },
    openLabel: (state) => {
      state.openedLabel = false;
    },
    hideLabel: (state) => {
      state.openedLabel = true;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      agentsApi.endpoints.getColors.matchFulfilled,
      (state, { payload }) => {
        state.colors = payload;
      }
    );
  },
});
export const { setColors, openLabel, hideLabel } = slice.actions;

export default slice.reducer;

export const selectColors = (state: RootState) => state.agents.colors;
export const selectOpened = (state: RootState) => state.agents.openedLabel;
