import { ReactNode } from "react";
import { modalStyles } from "./styles";
import closeIcon from "@assets/close.svg";
import { IconComponent } from "@new-ui-kit";

type ModalType = {
  active: boolean;
  setActive?: React.Dispatch<React.SetStateAction<boolean>>;
  children: ReactNode;
  big?: boolean;
  small?: boolean;
  scrollable?: boolean;
  nopadding?: boolean;
  playerHeight?: number;
  onCancel?: () => void;
  isTabletModal?: boolean;
};

const { ModalClose, ModalContent, StyledModal, ModalCloseWrapper } =
  modalStyles;
export const Modal: React.FC<ModalType> = ({
  active,
  onCancel: onClose,
  setActive,
  children,
  nopadding,
  big = false,
  small = false,
  playerHeight = 0,
  isTabletModal = false,
}) => {
  const handleCloseClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (onClose) {
      onClose();
    } else {
      if (setActive) setActive(false);
    }
  };

  return (
    <StyledModal
      open={active}
      onClose={handleCloseClick}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      bottom={playerHeight + "px"}
    >
      <ModalContent
        nopadding={nopadding}
        big={big}
        small={small}
        isTabletModal={isTabletModal}
      >
        <ModalCloseWrapper>
          <ModalClose id="modalCloseButton" onMouseDown={handleCloseClick}>
            <IconComponent Icon={closeIcon} />
          </ModalClose>
        </ModalCloseWrapper>
        {children}
      </ModalContent>
    </StyledModal>
  );
};
