import React, { FC, Fragment } from "react";
import { TPage } from "../types";
import { Page404Styles } from "../styles";
import notFoundImage from "@assets/e.webp";
import Image from "next/image";

const { Header, ImageWrapper } = Page404Styles;

type Props = {
  page: TPage;
};

const MainImage: FC<Props> = ({ page }) => {
  switch (page) {
    case "404Error":
      return (
        <>
          <Header>4</Header>
          <ImageWrapper>
            <Image alt="Ошибка 404" src={notFoundImage} />
          </ImageWrapper>
          <Header>4</Header>
        </>
      );
    default:
      return <Fragment></Fragment>;
  }
};

export default MainImage;
