import Link from "next/link";
import React, { MouseEvent, memo } from "react";
import styled, { css } from "styled-components";

interface IStyledIconComponent {
  rotate?: boolean;
  disabled?: boolean;
  height?: number;
  width?: number;
  isSidebar?: boolean;
  hovered?: boolean;
  selected?: boolean;
  pointer?: boolean;
  className?: string;
  noHover?: boolean;
  visible?: boolean;
  grab?: boolean;
  $customVisible?: boolean;
  noBorder?: boolean;
}

interface IIconComponent {
  rotate?: boolean;
  disabled?: boolean;
  Icon: any;
  onClick?: any;
  height?: number;
  width?: number;
  isSidebar?: boolean;
  hovered?: boolean;
  selected?: boolean;
  pointer?: boolean;
  stroke?: string;
  strokeWidth?: string;
  style?: any;
  noBorder?: boolean;
  href?: string;
  grab?: boolean;
  className?: string;
  customVisible?: boolean;
  visible?: boolean;
  noHover?: boolean;
  onMouseLeave?: () => void;
}

const IconComponent: React.FC<IIconComponent> = ({
  Icon,
  rotate = false,
  disabled = false,
  onClick = (e: MouseEvent) => {},
  isSidebar,
  hovered = false,
  selected = false,
  pointer = false,
  stroke = null,
  strokeWidth = null,
  href = null,
  style,
  noBorder,
  noHover = false,
  grab = false,
  className = "",
  customVisible = false,
  visible = true,
  onMouseLeave,
}) => {
  const StyledIconComponent = styled(Icon ?? "div")<IStyledIconComponent>`
    ${({ $customVisible, $visible }) =>
      $customVisible &&
      css`
        display: ${$visible ? "block" : "none"} !important;
      `}
    ${({ $pointer, disabled }) =>
      $pointer &&
      css`
        cursor: ${disabled ? "inherit" : "pointer"} !important;
      `}

    transition: 0.2s ease-out;
    stroke: ${({ theme, stroke }) => (stroke ? stroke : theme.text.dark_gray)};
    path {
      transition: 0.2s ease-out;
      stroke: ${({ theme, stroke }) =>
        stroke ? stroke : theme.text.dark_gray};
    }
    stroke-width: ${({ strokeWidth }) => (strokeWidth ? strokeWidth : 2)};
    ${({ $rotate }) =>
      $rotate &&
      css`
        transition: 0.2s ease-out;
        transform: rotate(180deg);
      `}
    ${({ disabled, theme }) =>
      disabled &&
      css`
        stroke: ${({ theme }) => disabled && theme.stroke.default};
      `}
    &:hover {
      stroke: ${({ theme, disabled, $noHover }) =>
        !disabled && !$noHover && theme.text.black};
      path {
        transition: 0.2s ease-out;
        stroke: ${({ theme, disabled, $noHover }) =>
          !disabled && !$noHover && theme.text.black};
      }
    }
    ${({ $hovered, $noHover }) =>
      $hovered &&
      !$noHover &&
      css`
        transition: 0.2s ease-out;
        stroke: ${({ theme }) => theme.text.black};
        path {
          transition: 0.2s ease-out;
          stroke: ${({ theme }) => !disabled && theme.text.black};
        }
        transition: 0.2s ease-out;
        &:hover {
          stroke: ${({ theme }) => theme.text.black};
          path {
            transition: 0.2s ease-out;
            stroke: ${({ theme }) => !disabled && theme.text.black};
          }
        }
      `}
    ${({ $isSidebar, selected }) =>
      $isSidebar &&
      selected &&
      css`
        transition: 0.2s ease-out;
        stroke: ${({ theme }) => theme.main_violet};
        &:hover {
          stroke: ${({ theme }) => theme.main_violet};
          path {
            stroke: ${({ theme }) => theme.main_violet};
          }
        }
        path {
          stroke: ${({ theme }) => theme.main_violet};
        }
      `}
    ${({ $noBorder }) =>
      $noBorder &&
      css`
        stroke-width: 0;
      `}
    ${({ $grab }) =>
      $grab &&
      css`
        cursor: grab !important;
        cursor: -moz-grab !important;
        cursor: -webkit-grab !important;
        &:active {
          cursor: grabbing !important;
          cursor: -moz-grabbing !important;
          cursor: -webkit-grabbing !important;
        }
      `}
  `;

  return (
    <>
      {href ? (
        <Link href={href}>
          <StyledIconComponent
            $pointer={pointer}
            stroke={disabled ? "#E4E4E7" : stroke ? stroke : "#636366"}
            $hovered={hovered}
            selected={selected}
            $isSidebar={isSidebar}
            $rotate={rotate}
            disabled={disabled}
            strokeWidth={strokeWidth}
            style={style}
            $noBorder={noBorder}
            $grab={grab}
            $visible={visible}
            $customVisible={customVisible}
          />
        </Link>
      ) : (
        <div
          onMouseLeave={onMouseLeave}
          className={className}
          style={{
            display: "flex",
            width: "fit-content",
            alignItems: "center",
            margin: 0,
            height: "100%",
            cursor: disabled ? "not-allowed" : "inherit",
          }}
        >
          <StyledIconComponent
            $pointer={pointer}
            stroke={disabled ? "#E4E4E7" : stroke ? stroke : "#636366"}
            $hovered={hovered}
            $noHover={noHover}
            selected={selected}
            $isSidebar={isSidebar}
            $rotate={rotate}
            disabled={disabled}
            onClick={() => !disabled && onClick()}
            strokeWidth={strokeWidth}
            style={style}
            $noBorder={noBorder}
            $grab={grab}
            $visible={visible}
            $customVisible={customVisible}
          />
        </div>
      )}
    </>
  );
};

export default memo(IconComponent);
