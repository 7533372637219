import { ChangeEvent, MouseEventHandler, useEffect, useState } from "react";
import { NextPage } from "next";
import { Flex } from "@new-ui-kit";
import { InputStyles } from "./styles";
import { InputPropsInterface, InputStateType } from "./types";
import { inputConfig } from "./config";

import {
  useMobileMediaQuery,
  useTabletAndBelowMediaQuery,
  useTouchQuery,
} from "@helpers/hooks/apaptive";
import InputDescription from "./components/InputDescription";

const { StyledInput, InputContainer, StyledLabel } = InputStyles;
const { stateFormatter } = inputConfig;

export const Input: NextPage<InputPropsInterface> = ({
  id = "",
  name = "",
  value = "",
  label = "",
  type = "text",
  disabled = false,
  readOnly = false,
  pattern = "input",
  width = "264px",
  maxWidth = "100%",
  startContent = <></>,
  endContent = <></>,
  bottomContent = null,
  onClick,
  onChange,
  onFocus: inputFocusFunc,
  onBlur: inputBlurFunc,
  onInputClick,
  noFocus = false,
  noHover = false,
  isColored,
  errors = null,
  successDescription,
  defaultDescription,
  showDescription = false,
  isSuccessDescription,
  inputProps,
  placeholder,
  maxLength = 64,
  isSmallest,
  isChangeSize,
  isAdaptiveHeight = true,
  isNumeric = false,
  ignoreIconClick = false,
  hasPadding = false,
}) => {
  const inputPropsValue = inputProps?.value || "";
  //  Типы инпутов:
  const isInput = pattern === "input";

  const isSearch = pattern === "search";
  const isDropdown = pattern === "dropdown";

  const isEmail = pattern === "email";
  const isPassword = pattern === "password";
  const isCalendar = pattern === "calendar" && isColored;
  //  Состояния:
  const [state, setState] = useState<InputStateType>("default");
  const [inputValue, setInputValue] = useState(value || inputPropsValue);
  const [hover, setHover] = useState(false);
  const [focused, setFocused] = useState(false);
  const [filled, setFilled] = useState(false);
  const [errorDefault, setErrorDefault] = useState(false);
  const [validation, setValidation] = useState(false);
  const [success, setSuccess] = useState(false);

  const coloredFilled = isSearch || isCalendar || isDropdown;

  //  Прочие константы:
  const isTabletOrMobile = useTabletAndBelowMediaQuery();
  const isMobile = useMobileMediaQuery();
  const isTouchDevice = useTouchQuery();

  const isHaveValue =
    isTabletOrMobile && isDropdown
      ? true
      : Boolean(value || inputValue || inputPropsValue);
  const reducedLabel = (state === "focused" && !readOnly) || isHaveValue;
  const errorTypes = ["oneOf", "required", "error", "typeError", "notOneOf"];
  const activeError = errors && errors[name] && errors[name]?.type;

  //  Управление состояниями:
  const onClickInput: MouseEventHandler<HTMLInputElement> = (e) => {
    if (onInputClick) onInputClick(e);
    onFocus();
  };
  const onFocus = () => {
    if (inputFocusFunc) inputFocusFunc();
    setValidation(false);
    setSuccess(false);
    setErrorDefault(false);
    setHover(false);
    setFocused(true);
  };
  const onBlur = () => {
    if (inputBlurFunc) inputBlurFunc();
    setFocused(false);
  };
  const onMouseEnter = () => {
    setHover(true);
  };

  const onMouseLeave = () => {
    setHover(false);
  };
  const filledHandler = () => setFilled(Boolean(value));
  const stateHandler = () =>
    setState(
      stateFormatter({
        disabled,
        focused,
        hover,
        filled,
        noFocus,
        noHover,
        errorDefault,
        validation,
        success,
      })
    );
  const inputHandler = (e: ChangeEvent) => {
    if (onChange) onChange(e as ChangeEvent<HTMLInputElement>);
  };

  useEffect(() => {
    filledHandler();
  }, [focused, hover, value, filled]);

  useEffect(() => {
    stateHandler();
  }, [
    hover,
    focused,
    filled,
    validation,
    success,
    errorDefault,
    disabled,
    noFocus,
    noHover,
  ]);

  useEffect(() => {
    if (value === "") {
      setInputValue("");
    }
  }, [value]);

  useEffect(() => {
    if (isDropdown && isColored) {
      setFocused(true);
      setFilled(true);
    }
  }, [isColored, focused, isDropdown, filled]);

  useEffect(() => {
    if (!isColored) {
      setFocused(false);
      setHover(false);
    }
  }, [isColored]);

  useEffect(() => {
    if (activeError && !focused && name && (isInput || isPassword || isEmail)) {
      if (
        errors[name] &&
        errors[name]?.type &&
        errorTypes.includes(errors[name]?.type)
      ) {
        setValidation(false);
        setSuccess(false);
        setErrorDefault(true);
        setFocused(false);
        setHover(false);
      } else if (errors[name] && errors[name]?.type) {
        setSuccess(false);
        setErrorDefault(false);
        setValidation(true);
        setFocused(false);
        setHover(false);
      }
    } else if (
      name &&
      value &&
      ((errors && !errors[name]) || !errors) &&
      !focused
    ) {
      setErrorDefault(false);
      setValidation(false);
      setSuccess(true);
      setFocused(false);
      setHover(false);
    }
  }, [activeError, inputValue, focused, errors]);

  const returnInputWidth = () => {
    const letterCoefficient = isMobile ? 5 : 7.5;
    const textIndentation = isMobile ? 35 : isCalendar ? 20 : 30;
    const lebelIndentation = isMobile ? 30 : isCalendar ? 20 : 50;

    const labelLength = label.length * letterCoefficient + lebelIndentation;
    const valueLength = value.length * letterCoefficient + textIndentation;
    if (isTabletOrMobile && isChangeSize) {
      return value && valueLength > labelLength
        ? valueLength + "px"
        : labelLength + "px";
    } else {
      return "100%";
    }
  };

  return (
    <Flex flexDirection="column" width="100%" disabled={disabled}>
      <InputContainer
        state={state}
        width={width}
        maxWidth={maxWidth}
        onBlur={onBlur}
        onMouseEnter={isTouchDevice ? undefined : onMouseEnter}
        onMouseLeave={isTouchDevice ? undefined : onMouseLeave}
        pointer={readOnly && !disabled}
        unwrapBottom={bottomContent !== null}
        coloredFilled={coloredFilled}
        isSmallestInput={isSmallest}
        isAdaptiveHeight={
          !isSearch && !isInput && !isPassword && isAdaptiveHeight
        }
        isChangeSize={isChangeSize}
        noHover={noHover}
      >
        <Flex
          justifyContent="center"
          alignItems="center"
          width="100%"
          height="100%"
          maxHeight="100%"
          padding={bottomContent !== null || hasPadding ? "4px 16px" : "0"}
          onClick={(e) => {
            onClick && onClick();
          }}
        >
          {startContent}
          <Flex
            flexDirection="column"
            justifyContent="flex-start"
            width={returnInputWidth()}
            onClick={onClickInput}
          >
            <StyledLabel
              state={state}
              reducedLabel={reducedLabel}
              cursor={disabled ? "not-allowed" : readOnly ? "pointer" : "text"}
              isSmallest={isSmallest}
              htmlFor={id}
            >
              {label}
            </StyledLabel>
            <StyledInput
              id={id}
              state={state}
              value={isDropdown && !value ? "Все" : value}
              onChange={inputHandler}
              onClick={onFocus}
              onFocus={onFocus}
              onBlur={onBlur}
              disabled={disabled}
              reducedLabel={reducedLabel}
              readOnly={readOnly}
              pointer={readOnly}
              coloredFilled={coloredFilled}
              placeholder={placeholder}
              maxLength={maxLength}
              type={type}
              isSmallestInput={isSmallest}
              inputMode={isNumeric ? "decimal" : undefined}
              {...inputProps}
            />
          </Flex>
          <Flex
            onClick={(e) => {
              if (e && ignoreIconClick) e.stopPropagation();
            }}
          >
            {endContent}
          </Flex>
        </Flex>

        {bottomContent}
      </InputContainer>
      {showDescription ? (
        <InputDescription
          name={name}
          errors={errors}
          isSuccessDescription={isSuccessDescription}
          successDescription={successDescription}
          defaultDescription={defaultDescription}
        />
      ) : null}
    </Flex>
  );
};
