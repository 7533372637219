import { TPage } from "./types";

const pageTitle = (page: TPage) => {
  switch (page) {
    case "404Error":
      return "Страница не найдена";
    case "linkOutdated":
      return "Эта ссылка устарела";
    default:
      return "";
  }
};
const pageSubtitle = (page: TPage) => {
  switch (page) {
    case "404Error":
      return `Вероятно, это произошло из-за опечатки в адресе,
        или страница была удалена.
        `;
    case "linkOutdated":
      return `Вероятно, вы перешли по ссылке из письма,
        отправленного более 24–х часов назад.
        `;
    default:
      return "";
  }
};
const buttonText = (page: TPage) => {
  switch (page) {
    case "404Error":
      return "Вернуться в личный кабинет";
    case "linkOutdated":
      return "Получить новую ссылку";
    default:
      return "";
  }
};

export const switchErrorPageMessages = { pageTitle, pageSubtitle, buttonText };
