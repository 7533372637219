import React, { ReactNode, useEffect, useRef, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { createPortal } from "react-dom";
import styled from "styled-components";
import { Column, Text } from "@new-ui-kit";
import successIcon from "@assets/notifyIcons/success.svg";
import errorIcon from "@assets/notifyIcons/errorIcon.svg";
import infoIcon from "@assets/notifyIcons/info.svg";
import breakpoints from "@styles/breakpoints";

const ToastBody = styled(ToastContainer)`
  padding: 0;

  width: 380px !important;
  @media ${breakpoints.mobile} {
    width: 100% !important;
    max-width: 100% !important;
  }
  .Toastify__toast-body {
    display: flex;
    align-items: flex-start;
    padding: 0;
    gap: 6px;
    font-family: inherit;
  }
  .Toastify__toast-theme--light.Toastify__toast--info {
    background: #f3f3ff;
    border: 1px solid ${({ theme }) => theme.main_violet};
    border-radius: 4px;
    padding: 19px;
    .Toastify__toast-icon {
      width: 30px !important;
      margin: 0;
    }
  }
  .Toastify__progress-bar--info {
    background: #4f46e5;
  }

  .Toastify__toast-theme--light.Toastify__toast--error {
    background: #fff5f5;
    border: 1px solid #ff9696;
    border-radius: 4px;
    padding: 19px;
    .Toastify__toast-icon {
      width: 30px !important;
      margin: 0;
    }
  }
  .Toastify__progress-bar--success {
    background: #ea0000;
  }

  .Toastify__toast-theme--light.Toastify__toast--success {
    background: #f1ffee;
    border: 1px solid #a5e795;
    border-radius: 4px;
    padding: 19px;

    .Toastify__toast-icon {
      width: 30px !important;
      margin: 0;
    }
  }
  .Toastify__progress-bar--success {
    background: #3ec91c;
  }
`;

export const infoNotify = (title: string, subtitle: string, id: string) => {
  const msg = (
    <Column gap={8} margin={subtitle.length === 0 ? "3px 0 0 0" : ""}>
      <Text weight={600} pattern="headline4">
        {title}
      </Text>
      {subtitle.length > 0 && <Text pattern="body2">{subtitle}</Text>}
    </Column>
  );
  toast.info(msg, { toastId: id, icon: infoIcon });
};

export const successNotify = (title: string, subtitle: string, id: string) => {
  const msg = (
    <Column gap={8}>
      <Text weight={700} pattern="headline4">
        {title}
      </Text>
      <Text pattern="body2">{subtitle}</Text>
    </Column>
  );
  toast.success(msg, {
    toastId: id,
    icon: successIcon,
  });
};

export const errorNotify = (title: string, subtitle: string, id: string) => {
  const msg = (
    <Column gap={8}>
      <Text weight={600} pattern="headline4">
        {title}
      </Text>
      <Text pattern="body2">{subtitle}</Text>
    </Column>
  );
  toast.error(msg, { toastId: id, icon: errorIcon });
};

interface PortalProps {
  children: ReactNode;
  selector: string;
}

export const ClientOnlyPortal = ({ children, selector }: PortalProps) => {
  const ref = useRef<Element | null>(null);
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    ref.current = document.querySelector<HTMLElement>(selector);
    setMounted(true);
  }, [selector]);

  return mounted && ref.current ? createPortal(children, ref.current) : null;
};

export function Toast() {
  return createPortal(
    <div>
      <ClientOnlyPortal selector="#modal">
        <ToastBody position="top-right" />
      </ClientOnlyPortal>
    </div>,
    document.body
  );
}
