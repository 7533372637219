import { endOfDay, startOfDay } from "date-fns";
import dayjs from "dayjs";

export const FULL_PERIOD_FORMAT = "YYYY-MM-DDTHH:mm:ss";
export const emptyFunction = () => {};
export const formatDate = "yyyy-MM-dd'T'HH:mm:ss";
export const dashboardFormatDate = "yyyy-MM-dd";
export const smallFormatDate = "d.MM";
export const endToday = endOfDay(new Date());
export const startWeek = startOfDay(
  new Date().setDate(new Date().getDate() - 6)
);
export const currentYear = new Date().getFullYear();

export const CURRENT_YEAR = dayjs().format("YYYY");
export const CURRENT_MONTH = dayjs().month() + 1;
export const NO_DATA_TITLE = "Нет данных за выбранный период";
export const MAX_MODAL_HEIGHT = 656;

export const EMPTY_FUNCTION = () => {};

export const modalElementsHeight = {
  searchHeight: 88,
  headerHeight: 48,
  buttonsHeight: 80,
};

export const MONTHS_LIST = [
  {
    id: 1,
    title: "Январь",
  },
  {
    id: 2,
    title: "Февраль",
  },
  {
    id: 3,
    title: "Март",
  },
  {
    id: 4,
    title: "Апрель",
  },
  {
    id: 5,
    title: "Май",
  },
  {
    id: 6,
    title: "Июнь",
  },
  {
    id: 7,
    title: "Июль",
  },
  {
    id: 8,
    title: "Август",
  },
  {
    id: 9,
    title: "Сентябрь",
  },
  {
    id: 10,
    title: "Октябрь",
  },
  {
    id: 11,
    title: "Ноябрь",
  },
  {
    id: 12,
    title: "Декабрь",
  },
];

export const WEEK_DAYS = [
  {
    id: 1,
    title: "Пн",
  },
  {
    id: 2,
    title: "Вт",
  },
  {
    id: 3,
    title: "Ср",
  },
  {
    id: 4,
    title: "Чт",
  },
  {
    id: 5,
    title: "Пт",
  },
  {
    id: 6,
    title: "Сб",
  },
  {
    id: 7,
    title: "Вс",
  },
];
export type TAgentType = "INBOUND" | "OUTBOUND" | "MIXED";
export enum RolesEnum {
  ALL_PRIVILEGES = "ALL_PRIVILEGES",
  BASE_PRIVILEGES = "BASE_PRIVILEGES",
  BASE_ANALYTIC_PRIVILEGES = "BASE_ANALYTIC_PRIVILEGES",
}
export const availiblePages = {
  [RolesEnum.ALL_PRIVILEGES]: {
    "": true,
    agents: true,
    support: true,
    settings: true,
    auth: true,
  },
  [RolesEnum.BASE_PRIVILEGES]: {
    "": false,
    agents: true,
    support: true,
    settings: true,
    auth: true,
  },
  [RolesEnum.BASE_ANALYTIC_PRIVILEGES]: {
    "": false,
    agents: true,
    support: true,
    settings: true,
    auth: true,
  },
};

export const availibleTabs = {
  [RolesEnum.ALL_PRIVILEGES]: {
    dashboard: true,
    calls: true,
    sessions: true,
    reports: true,
  },
  [RolesEnum.BASE_PRIVILEGES]: {
    dashboard: false,
    calls: true,
    sessions: false,
    reports: false,
  },
  [RolesEnum.BASE_ANALYTIC_PRIVILEGES]: {
    dashboard: false,
    calls: true,
    sessions: false,
    reports: true,
  },
};

export const filtersDictionary = {
  call_result: "Результат звонка",
  call_status: "Код ответа телефонии",
  call_duration: "Длительность",
  call_start_time: "Дата звонка",
  last_attempt: "Последняя попытка",
  voicemail_detection: "Определён автоответчик",
  call_direction: "Направление вызова",
};

export const ALL_VALUES_FILTER = "Выбрать все";

export const EMPTY_INPUT_TABS = ["reports"];

export const COPY_DONE = "Скопировано ✔";

export const ALIAS_FOR_NULL = "Пустое значение";

export const TABLET_WIDGET_WIDTH = 337;

export const MOBILE_WIDGET_WIDTH = 343;

export const SMALL_TABLET_WIDGET_HEIGHT = 115;

export const MEDIUM_TABLET_WIDGET_HEIGHT = 230;

export const LARGE_TABLET_WIDGET_HEIGHT = 363;

export const FORCE_RUSSIFICATION = {
  // for Boolean values true means applying Russification
  booleanTrue: {
    true: "Да",
    false: "True",
  },
  booleanFalse: {
    true: "Нет",
    false: "False",
  },
  agentTypeIn: {
    rus: "Входящий",
    default: "inbound",
  },
  agentTypeOut: {
    rus: "Исходящий",
    default: "outbound",
  },
  agentTypeMix: {
    rus: "Смешанный",
    default: "mixed",
  },
};
