import { NextPage } from "next";

import { Flex, Button } from "@new-ui-kit";
import { Page404Styles } from "./styles";
import { TPage } from "./types";
import { switchErrorPageMessages } from "./config";

import MainLogo from "@components/Sidebar/components/MainLogo";
import MainImage from "./components/MainImage";
import { Fragment } from "react";
import { useTabletAndBelowMediaQuery } from "@helpers/hooks/apaptive";

const { pageTitle, pageSubtitle, buttonText } = switchErrorPageMessages;
const {
  Wrapper,
  Container,
  Title,
  Subtitle,
  ButtonWrapper,
  LogoWrapper,
  CommonTabletContainer,
} = Page404Styles;

const ErrorPageLayout: NextPage<{
  page: TPage;
  onClick?: () => void;
}> = ({ page, onClick }) => {
  const isTablet = useTabletAndBelowMediaQuery();

  const Adaptive = isTablet ? CommonTabletContainer : Fragment;
  return (
    <Wrapper>
      <Adaptive>
        <LogoWrapper>
          <MainLogo isNotFoundPage />
        </LogoWrapper>
        <Container isOutdatedLink={page === "linkOutdated"}>
          <Flex alignItems="center">
            <MainImage page={page} />
          </Flex>
          <Title color="black">{pageTitle(page)}</Title>
          <Subtitle color="black">{pageSubtitle(page)}</Subtitle>
          <ButtonWrapper>
            <Button pattern="primary" fullWidth onClick={onClick}>
              {buttonText(page)}
            </Button>
          </ButtonWrapper>
        </Container>
      </Adaptive>
    </Wrapper>
  );
};

export default ErrorPageLayout;
