import { agentsApi } from "./features/agents/agents-api-slice";
import { userApi, resetPasswordApi } from "./features/user/user-api-slice";
/* eslint-disable import/no-cycle */
import { configureStore } from "@reduxjs/toolkit";

import { AnyAction, combineReducers } from "redux";

import { persistStore, persistReducer } from "redux-persist";

import storage from "redux-persist/lib/storage";

import { createWrapper } from "next-redux-wrapper";

import authReducer from "./features/auth/auth-slice";

import agentsReducer from "./features/agents/agents-slice";
import tokensReducer from "./features/tokens/tokens-slice";
import labelsReducer from "./features/labels/labels-slice";
import statusesReducer from "./features/reports/reports-slice";
import userReducer from "./features/user/user-slice";
import { authApi } from "./features/auth/auth-api-slice";
import { dashboardsApi } from "@features/dashboards/dashboards-api-slice";
import { labelsApi } from "@features/labels/labels-api-slice";
import { reportsApi } from "@features/reports/reports-api-slice";
import { widgetsApi } from "@features/widgets/widgets-api-slice";
import { tokensApi } from "@features/tokens/tokens-api-slice";
import widgetReducer from "@features/widgets/widgets-slice";

const appReducer = combineReducers({
  [authApi.reducerPath]: authApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [resetPasswordApi.reducerPath]: resetPasswordApi.reducer,
  [agentsApi.reducerPath]: agentsApi.reducer,
  [widgetsApi.reducerPath]: widgetsApi.reducer,
  [dashboardsApi.reducerPath]: dashboardsApi.reducer,
  [labelsApi.reducerPath]: labelsApi.reducer,
  [reportsApi.reducerPath]: reportsApi.reducer,
  [tokensApi.reducerPath]: tokensApi.reducer,
  user: userReducer,
  auth: authReducer,
  tokens: tokensReducer,
  agents: agentsReducer,
  labels: labelsReducer,
  reports: statusesReducer,
  widgets: widgetReducer,
});

const reducerProxy = (
  state: ReturnType<typeof appReducer>,
  action: AnyAction
) => {
  if (action.type === "profile/clearProfile") {
    return appReducer(undefined, { type: undefined });
  }
  return appReducer(state, action);
};

export const makeStore = ({ isServer }: { isServer: boolean }) => {
  const middleware = (getDefaultMiddleware: any) =>
    getDefaultMiddleware({ serializableCheck: false })
      .concat(authApi.middleware)
      .concat(agentsApi.middleware)
      .concat(userApi.middleware)
      .concat(resetPasswordApi.middleware)
      .concat(widgetsApi.middleware)
      .concat(dashboardsApi.middleware)
      .concat(labelsApi.middleware)
      .concat(reportsApi.middleware)
      .concat(tokensApi.middleware);
  if (isServer) {
    return configureStore({
      // @ts-expect-error
      reducer: reducerProxy,
      middleware,
    });
  }

  const persistConfig = {
    key: "tokens",
    whitelist: ["auth"],
    storage,
  };

  // Create a new reducer with our existing reducer
  // @ts-expect-error
  const persistedReducer = persistReducer(persistConfig, reducerProxy);

  const store = configureStore({
    reducer: persistedReducer,
    middleware,
  });

  // This creates a persistor object & push that persisted object to .__persistor, so that we can avail the persistability feature
  // @ts-expect-error
  store.__persistor = persistStore(store);

  return store;
};

export type AppStore = ReturnType<typeof makeStore>;
export type RootState = ReturnType<typeof reducerProxy>;
export type AppDispatch = AppStore["dispatch"];
// @ts-expect-error
export const wrapper = createWrapper<AppStore>(makeStore, { debug: false });
