import styled, { css } from "styled-components";
import { ButtonPadding, ButtonTextSize } from "@new-ui-kit";
import { IButton } from "./types";
import breakpoints from "@styles/breakpoints";

const Container = styled.button<IButton>`
  display: flex;
  flex-direction: ${({ iconRight }) => (iconRight ? "row-reverse" : "row")};
  justify-content: center;
  align-items: center;
  gap: ${({ icon }) => (icon ? "6px" : 0)};
  font-weight: ${({ pattern }) =>
    pattern === "primary" ||
    pattern === "secondary" ||
    pattern === "tertiary" ||
    pattern === "delete"
      ? 500
      : 400};
  overflow: ${({ hidden }) => (hidden ? "hidden" : "inherit")};
  border-radius: ${({ pattern }) =>
    pattern === "link" || pattern === "deleteSecondary" ? "0px" : "10px"};
  transition: 0.2s ease-out;
  width: ${({ fullWidth }) => (fullWidth ? "100%" : "fit-content")};
  line-height: 24px;
  white-space: ${({ nowrap }) => (nowrap ? "nowrap" : "normal")};
  height: ${({ size, pattern, height }) =>
    height
      ? height
      : pattern === "deleteSecondary" || pattern === "link"
      ? "24px"
      : size === "normal"
      ? "48px"
      : size === "medium"
      ? "40px"
      : size === "small"
      ? "32px"
      : "auto"};
  padding: ${({ size, pattern }) =>
    pattern === "link" || pattern === "deleteSecondary"
      ? "0"
      : size && ButtonPadding[size]};
  font-size: ${({ size }) => size && ButtonTextSize[size]}px;
  background-color: ${({ pattern, theme }) => theme.btn[pattern].default.bg};
  color: ${({ pattern, theme }) => theme.btn[pattern].default.text};
  border: ${({ pattern, theme }) =>
    `${theme.btn[pattern].default.borderWidth} solid ${theme.btn[pattern].default.border}`};
  border-bottom: ${({ pattern, theme }) =>
    (pattern === "deleteSecondary" || pattern === "link") &&
    `${theme.btn[pattern].default.borderWidth} solid ${theme.btn[pattern].default.border}}`};
  a {
    text-decoration: none;
    transform: translateX(-1px);
    color: ${({ pattern, theme }) => theme.btn[pattern].default.text};
    &:hover {
      color: ${({ pattern, theme }) => theme.btn[pattern].default.text};
    }
  }
  span {
    overflow: ${({ isNowrapSpan }) => (isNowrapSpan ? "hidden" : "auto")};
    text-overflow: ${({ isNowrapSpan }) =>
      isNowrapSpan ? " ellipsis;" : "auto"};
  }
  &:hover {
    background-color: ${({ pattern, theme }) => theme.btn[pattern].hover.bg};
    color: ${({ pattern, theme }) => theme.btn[pattern].hover.text};
    border: ${({ pattern, theme }) =>
      `${theme.btn[pattern].hover.borderWidth} solid ${theme.btn[pattern].hover.border}`};
    border-bottom: ${({ pattern, theme }) =>
      (pattern === "deleteSecondary" || pattern === "link") &&
      `${theme.btn[pattern].hover.borderWidth} solid ${theme.btn[pattern].hover.text}}`};
  }
  @media ${breakpoints.tablet} {
    &:hover {
      background-color: ${({ pattern, theme }) =>
        theme.btn[pattern].default.bg};
    }
  }
  &:active {
    transition: 0.1s ease-out;
    background-color: ${({ pattern, theme }) => theme.btn[pattern].pressed.bg};
    color: ${({ pattern, theme }) => theme.btn[pattern].pressed.text};
    border: ${({ pattern, theme }) =>
      `${theme.btn[pattern].pressed.borderWidth} solid ${theme.btn[pattern].pressed.border}`};
    border-bottom: ${({ pattern, theme }) =>
      (pattern === "deleteSecondary" || pattern === "link") &&
      `${theme.btn[pattern].pressed.borderWidth} solid ${theme.btn[pattern].pressed.text}}`};
    padding: ${({ pattern }) =>
      pattern === "deleteSecondary" ? "0px" : "auto"};
  }
  &:disabled {
    cursor: not-allowed;
    background-color: ${({ pattern, theme }) => theme.btn[pattern].disabled.bg};
    color: ${({ pattern, theme }) => theme.btn[pattern].disabled.text};
    border: ${({ pattern, theme }) =>
      `${theme.btn[pattern].disabled.borderWidth} solid ${theme.btn[pattern].disabled.border}`};
    border-bottom: ${({ pattern, theme }) =>
      (pattern === "deleteSecondary" || pattern === "link") &&
      `${theme.btn[pattern].disabled.borderWidth} solid ${theme.btn[pattern].disabled.text}}`};
  }
  margin: ${({ margin }) => (margin ? margin : null)};
  ${({ isBorderTop }) =>
    isBorderTop &&
    css`
      border-top: 1px solid #e4e4e7;
      &:hover {
        border-top: 1px solid #e4e4e7;
      }
    `}
  ${({ isBorderBottom }) =>
    isBorderBottom &&
    css`
      border-bottom: 1px solid #e4e4e7;
      &:hover {
        border-bottom: 1px solid #e4e4e7;
      }
    `}
  ${({ isSidebar }) =>
    isSidebar &&
    css`
      padding: 12px 15px;
      justify-content: flex-start;
    `}
  ${({ isDropdown }) =>
    isDropdown &&
    css`
      padding: 12px 16px;
      justify-content: flex-start;
      border-radius: 0;
      text-align: start;
      line-height: 120%;
    `}
  ${({ isSidebar, selected, pattern, theme }) =>
    isSidebar &&
    selected &&
    css`
      background-color: ${theme.btn[pattern].hover.bg};
      color: ${theme.btn[pattern].hover.text};
      border: ${`2px solid ${theme.btn[pattern].hover.border}`};
      text-decoration: ${pattern === "link" && "underline"};
      &:hover {
        color: ${({ theme }) => theme.main_violet};
      }
    `}
  ${({ pattern }) =>
    pattern === "delete" &&
    css`
      padding: 12px 15px;
    `}
`;

export const ButtonStyles = { Container };
