import { ALIAS_FOR_NULL } from "@constants";

const formatStrictNameString = (obj: {
  [key: string]:
    | string
    | number
    | Array<string>
    | Array<number>
    | null
    | undefined;
}) => {
  let result = "";
  for (const key in obj) {
    const value = obj[key];
    if (Boolean(value) && !Array.isArray(value)) {
      if (result) {
        result += `&${key}=${value}`;
      } else {
        result += `?${key}=${value}`;
      }
    }
    if (Array.isArray(value) && value.length > 0) {
      if (result) {
        result += `&${key}=${value}`;
      } else {
        result += `?${key}=${value}`;
      }
    }
  }

  return result;
};

const formatQueryAsArray = (obj: {
  [key: string]:
    | string
    | number
    | Array<string>
    | Array<number>
    | null
    | undefined;
}) => {
  let result = "";
  for (const key in obj) {
    const value = obj[key];
    if (Boolean(value) && !Array.isArray(value)) {
      if (result) {
        result += `&${key}=${value}`;
      } else {
        result += `?${key}=${value}`;
      }
    }
    if (Array.isArray(value) && value.length > 0) {
      if (result) {
        result += `&${key}=[${value.map((it) => {
          const result = it === ALIAS_FOR_NULL ? "null" : it;
          return `"${result}"`;
        })}]`;
      } else {
        result += `?${key}=[${value.map((it) => {
          const result = it === ALIAS_FOR_NULL ? "null" : it;
          return `"${result}"`;
        })}]`;
      }
    }
  }
  return result;
};
export { formatStrictNameString, formatQueryAsArray };
