import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { labelsApi } from "./labels-api-slice";
import type { RootState } from "@store";
import { GetLabelsResponceInterface, LabelsType } from "@features/types";

const initialLabels: LabelsType = {
  labels: [],
};

const slice = createSlice({
  name: "labels",
  initialState: initialLabels,
  reducers: {
    setLabelsData: (
      state,
      { payload }: PayloadAction<GetLabelsResponceInterface>
    ) => {
      state.labels = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      labelsApi.endpoints.getLabels.matchFulfilled,
      (state, { payload }) => {
        state.labels = payload;
      }
    );
  },
});

export const { setLabelsData } = slice.actions;
export default slice.reducer;
export const selectLabels = (state: RootState) => state.labels.labels;
