import styled, { css } from "styled-components";
import { Text } from "@new-ui-kit";
import texture from "@public/loginPage/texture.webp";
import breakpoints from "@styles/breakpoints";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: ${({ theme }) => `${theme.lilac_light}`};
  background-image: url(${texture?.src});
  background-repeat: repeat;
  background-size: cover;
`;

const Container = styled.div<{ isOutdatedLink?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 780px;
  height: ${({ isOutdatedLink }) => (isOutdatedLink ? "auto" : "720px")};
  background: ${({ theme }) => theme.bg.main};
  border: ${({ theme }) => `1px solid ${theme.stroke.default}`};
  border-radius: 10px;
  padding: 104px 183px 125px;

  @media ${breakpoints.tablet} {
    width: 454px;
    height: 438px;
    padding: 70px 32px 48px;
  }

  @media ${breakpoints.mobile} {
    width: 343px;
    height: 454px;
    padding: 40px 18px 48px;
  }

  @media screen and (max-height: 1250px) {
    max-height: 590px;
  }
`;

const Header = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 208px;
  line-height: 140%;
  display: flex;
  align-items: center;
  text-align: center;
  color: ${({ theme }) => theme.main_violet};

  @media ${breakpoints.tablet} {
    font-size: 104px;
  }
`;

const ImageWrapper = styled.div<{ isOutdatedLink?: boolean }>`
  width: 160px;
  height: 161px;
  flex-shrink: 0;

  @media ${breakpoints.tablet} {
    width: 125px;
    height: 134px;
  }

  ${({ isOutdatedLink }) =>
    isOutdatedLink &&
    css`
      @media ${breakpoints.mobile} {
        width: 144px;
        height: 144px;
      }
    `}
`;

const Title = styled(Text)`
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 140%;
  margin-top: 8px;

  @media ${breakpoints.tablet} {
    font-size: 20px;
    font-weight: 600;
  }

  @media ${breakpoints.mobile} {
    margin-top: 26px;
  }
`;

const Subtitle = styled(Text)`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  line-height: 150%;
  margin-top: 16px;
`;

const ButtonWrapper = styled.div`
  margin-top: 48px;
  width: 408px;

  @media ${breakpoints.tablet} {
    width: 100%;
    margin-top: 32px;
  }

  @media screen and (max-height: 707px) {
    margin-top: 32px;
  }
`;

const LogoWrapper = styled.div`
  position: absolute;
  left: calc(20vw + 75px);
  top: 200px;
  margin-bottom: 40px;

  @media screen and (max-height: 1120px) {
    top: 5vh;
    left: 10vw;
  }
  @media screen and (max-height: 850px) {
    top: 0;
    left: 10vw;
  }
  @media ${breakpoints.tablet} {
    position: relative;
    margin-bottom: 24px;
    right: auto;
    left: 0;
    top: 0;
  }
`;

const CommonTabletContainer = styled.div`
  width: 454px;
  display: flex;
  flex-direction: column;

  @media ${breakpoints.mobile} {
    width: 343px;
  }
`;

export const Page404Styles = {
  Wrapper,
  Container,
  Header,
  ImageWrapper,
  Title,
  Subtitle,
  ButtonWrapper,
  LogoWrapper,
  CommonTabletContainer,
};
