import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "@store";
import { IFiltersResponce } from "@features/types";
import {
  createWidgetModalConfig,
  getAllFiltersSizes,
} from "@components/Modals/CreateWidgetModal/config";
import { compareLengthFilterCollections } from "@components/screens/agents/agentPage/components/dashboardPage/components/FilterInput/config";
import { ALIAS_FOR_NULL, ALL_VALUES_FILTER } from "@constants";
const { mergeFiltersWithLabel } = createWidgetModalConfig;

type FilterChangeType = {
  label: string | number;
  filter: string | number | boolean;
};

type SelectedFilterType = { [key: string]: Array<string | number | boolean> };

type FiltersForWidgetType = {
  filtersForWidget: IFiltersResponce | undefined;
  selectedFilters: SelectedFilterType;
};

const initialState: FiltersForWidgetType = {
  filtersForWidget: undefined,
  selectedFilters: {},
};

const slice = createSlice({
  name: "widgets",
  initialState: initialState,
  reducers: {
    // all filters for widget
    setAllFiltersForWidget: (
      state,
      { payload }: PayloadAction<IFiltersResponce | undefined>
    ) => {
      state.filtersForWidget = payload;
    },

    // select-unselect filter
    setInitialFilters: (
      state,
      { payload }: PayloadAction<unknown[] | SelectedFilterType>
    ) => {
      if (Array.isArray(payload)) {
        state.selectedFilters = {};
        return;
      }
      const temp = { ...payload };
      for (let filterGroup in temp) {
        if (temp.hasOwnProperty(filterGroup)) {
          temp[filterGroup] =
            temp[filterGroup].length > 0
              ? [
                  ...temp[filterGroup].map((x) =>
                    x === null || x === "null" ? ALIAS_FOR_NULL : x
                  ),
                ]
              : [];
        }
      }

      state.selectedFilters = temp;
    },

    // add filter
    addFilter: (state, { payload }: PayloadAction<FilterChangeType>) => {
      const { label, filter } = payload;
      const selectedFilters = state.selectedFilters[label] || [];

      if (selectedFilters.includes(filter)) {
        return;
      }

      const updatedFilters = [...selectedFilters, filter];

      state.selectedFilters = {
        ...state.selectedFilters,
        [label]: updatedFilters,
      };
    },

    // remove
    removeFilter: (state, { payload }: PayloadAction<FilterChangeType>) => {
      const { label, filter } = payload;
      const selectedFilters = state.selectedFilters[label];

      if (!selectedFilters) return;

      const updatedFilters = selectedFilters.filter(
        (item: any) => item !== filter
      );

      if (updatedFilters.length === 0) {
        delete state.selectedFilters[label];
      } else {
        state.selectedFilters[label] = updatedFilters;
      }
    },

    addAllFilters: (
      state,
      { payload }: PayloadAction<{ labelGroup: string }>
    ) => {
      const { labelGroup } = payload;
      if (!labelGroup || !state.filtersForWidget) {
        return;
      }

      const allOriginalFilters = state.filtersForWidget[labelGroup].filter(
        (it) => it !== ALL_VALUES_FILTER
      );

      state.selectedFilters[labelGroup] = allOriginalFilters;
    },

    removeAllFilters: (
      state,
      { payload }: PayloadAction<{ labelGroup: string }>
    ) => {
      const { labelGroup } = payload;
      if (!labelGroup) {
        return;
      }

      delete state.selectedFilters[labelGroup];
    },
  },
});
export const {
  setAllFiltersForWidget,
  setInitialFilters,
  addFilter,
  removeFilter,
  addAllFilters,
  removeAllFilters,
} = slice.actions;

export default slice.reducer;

export const selectAllFilters = (state: RootState) =>
  state.widgets.filtersForWidget;

export const selectSelectedFilters = (state: RootState) =>
  state.widgets.selectedFilters;

export const selectFiltersWithLabel = (state: RootState) => {
  const filterSizes =
    (state.widgets.filtersForWidget &&
      getAllFiltersSizes(state.widgets.filtersForWidget)) ||
    {};
  const widgetWithFormatLabel = mergeFiltersWithLabel(
    state.widgets.selectedFilters,
    filterSizes,
    state.user.force_translate
  );
  return widgetWithFormatLabel;
};

export const selectHasAllSelected =
  (field: string, isKey: boolean) =>
  (state: RootState): boolean => {
    return compareLengthFilterCollections({
      selected: state.widgets.selectedFilters,
      all: state.widgets.filtersForWidget,
      field,
      isKey: false,
    });
  };
