import breakpoints from "@styles/breakpoints";
import ScrollContainer from "react-indiana-drag-scroll";
import styled from "styled-components";

const BlurBefore = styled.div<{
  height?: string;
  opacity?: number;
  blurColor?: string;
}>`
  position: relative;
  width: 1px;
  height: ${({ height }) => (height ? height : "100%")};
  &::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    height: 100%;
    opacity: ${({ opacity }) => opacity};
    z-index: 13;
    width: 20px;
    pointer-events: none;
    cursor: inherit;
    left: 0px;
    transition-duration: 0.3s;
    border-bottom-left-radius: 15%;
    border-top-left-radius: 15%;
    background: linear-gradient(
      to right,
      rgba(${({ blurColor }) => blurColor}, 0.8),
      rgba(250, 250, 251, 0.1)
    );
  }
  @media ${breakpoints.tablet} {
    height: 24px;
  }
`;

const BlurAfter = styled.div<{
  right?: string;
  height?: string;
  opacity?: number;
  blurColor?: string;
  isTabletOrMobile?: boolean;
}>`
  position: relative;
  background-color: inherit;
  width: 2px;
  height: ${({ height }) => (height ? height : "100%")};
  &::after {
    content: "";
    pointer-events: none;
    position: absolute;
    opacity: ${({ opacity }) => opacity};
    top: 0;
    border-bottom-right-radius: 15%;
    border-top-right-radius: 15%;
    bottom: 0;
    width: 15px;
    z-index: 13;
    transition-duration: 0.3s;
    cursor: inherit;
    width: 25px;
    right: ${({ right, isTabletOrMobile }) =>
      isTabletOrMobile ? "0px" : right ? right : "0px"};
    background: linear-gradient(
      to left,
      rgba(${({ blurColor }) => blurColor}, 0.8),
      rgba(250, 250, 251, 0.1)
    );
  }
  @media ${breakpoints.tablet} {
    height: 24px;
  }
`;

const StyledScrollContainer = styled(ScrollContainer as any)<{
  isGrab?: boolean;
}>`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  div {
    cursor: ${({ isGrab }) => (isGrab ? "grab" : "default")} !important;
    cursor: ${({ isGrab }) => (isGrab ? "-moz-grab" : "default")} !important;
    cursor: ${({ isGrab }) => (isGrab ? "-webkit-grab" : "default")} !important;

    &:active {
      cursor: ${({ isGrab }) => (isGrab ? "grabbing" : "default")} !important;
      cursor: ${({ isGrab }) =>
        isGrab ? "-moz-grabbing" : "default"} !important;
      cursor: ${({ isGrab }) =>
        isGrab ? "-webkit-grabbing" : "default"} !important;
    }
  }
`;

export const WrapperStyles = {
  StyledScrollContainer,
  BlurBefore,
  BlurAfter,
};
