import { forceTranslateString } from "@utils";
import { IWidgetsTypes } from "@components/Widgets/types";
import { ILayoutDataItem } from "@components/screens/agents/agentPage/components/dashboardPage/types";
import { IFiltersResponce } from "@features/types";
import { format, startOfDay } from "date-fns";
import {
  ALIAS_FOR_NULL,
  ALL_VALUES_FILTER,
  FORCE_RUSSIFICATION,
  smallFormatDate,
} from "@constants";

function russifier(key: string) {
  switch (key) {
    case "call_direction":
      return "Направление вызова";
    case "call_result":
      return "Результат звонка";
    case "voicemail_detection":
      return "Определён автоответчик";
    case "last_attempt":
      return "Последняя попытка";
    case "call_status":
      return "Код ответа телефонии";
    case "'call_uuid":
      return "Идентификатор звонка";
    case "msisdn":
      return "Номер телефона";
    case "dialog_uuid":
      return "Идентификатор диалога";
    case "call_start_time":
      return "Время начала звонка";
    case "queue_uuid":
      return "Идентификатор очереди";
    case "call_record":
      return "Запись звонка";
    case "call_duration":
      return "Продолжительность звонка";
    case "call_transcription":
      return "Транскрипция звонка";
    case "agent_id":
      return "Идентификатор агента";

    default:
      return key
        .replace(new RegExp("_", "g"), " ")
        .split(/\s+/)
        .map((word) => word[0].toUpperCase() + word.substring(1))
        .join(" ");
  }
}

const filtersNamesFormatter = (filters: IFiltersResponce) => {
  return Object.keys(filters).map((item) => {
    const title = russifier(item);
    return {
      title: title,
      label: item,
      checkbox: true,
    };
  });
};

const MetricNamesFormatter = (
  filters: IFiltersResponce,
  selectMetric: (item: {
    title: string | number;
    label: string | number;
  }) => void
) => {
  return Object.keys(filters).map((item) => {
    const title = russifier(item);

    return {
      title: title,
      label: item,
      onClick: () => selectMetric({ title: title, label: item }),
    };
  });
};

const metricValuesFormatter = (
  label: string | number,
  filters: IFiltersResponce,

  selectMetric: (item: {
    title: string | number;
    label: string | number;
  }) => void,
  isForceRussification: boolean = false
) => {
  const forceKey = isForceRussification.toString() as "true" | "false";
  return filters[label]
    .filter((item) => item !== null && item !== ALL_VALUES_FILTER)
    .map((item) => {
      const label_ =
        typeof item === "boolean" && item
          ? 1
          : typeof item === "boolean" && !item
          ? 0
          : item;

      const title_ =
        typeof item === "boolean" && item
          ? FORCE_RUSSIFICATION.booleanTrue[forceKey]
          : typeof item === "boolean" && !item
          ? FORCE_RUSSIFICATION.booleanFalse[forceKey]
          : forceTranslateString(item.toString(), isForceRussification);

      return {
        title: title_,
        label: label_,
        onClick: () => selectMetric({ title: title_, label: label_ }),
      };
    });
};

const filterValuesFormatter = (
  label: string | number,
  filters: IFiltersResponce,
  isForceRussification: boolean = false
) => {
  const forceKey = isForceRussification.toString() as "true" | "false";
  return filters[label]
    .filter((item) => item !== null)
    .map((item) => {
      const title_ =
        typeof item === "boolean" && item
          ? FORCE_RUSSIFICATION.booleanTrue[forceKey]
          : typeof item === "boolean" && !item
          ? FORCE_RUSSIFICATION.booleanFalse[forceKey]
          : forceTranslateString(item.toString(), isForceRussification);
      return {
        title: title_,
        label: item,
        checkbox: true,
      };
    });
};

const titleFormatter = (type: IWidgetsTypes) => {
  switch (type) {
    case "numeric":
      return "Показатель";
    case "bar":
      return "Столбчатая диаграмма";
    case "doughnut":
      return "Круговая диаграмма";
    case "linear":
      return "Линейная диаграмма";
    case "time":
      return "Средняя длительность разговора";
    case "uniq_time":
      return "Средняя длительность разговора";
    default:
      return " ";
  }
};

const getLastWeek = () => {
  return [
    format(
      startOfDay(new Date().setDate(new Date().getDate() - 6)),
      smallFormatDate
    ),
    format(
      startOfDay(new Date().setDate(new Date().getDate() - 5)),
      smallFormatDate
    ),
    format(
      startOfDay(new Date().setDate(new Date().getDate() - 4)),
      smallFormatDate
    ),
    format(
      startOfDay(new Date().setDate(new Date().getDate() - 3)),
      smallFormatDate
    ),
    format(
      startOfDay(new Date().setDate(new Date().getDate() - 2)),
      smallFormatDate
    ),
    format(
      startOfDay(new Date().setDate(new Date().getDate() - 1)),
      smallFormatDate
    ),
    format(startOfDay(new Date()), smallFormatDate),
  ];
};

const initialWidgetFormFormatter = (
  agent_uuid: string,
  type: IWidgetsTypes,
  widgetsLayout: {
    layout: Array<ILayoutDataItem> | [];
  }
) => {
  const isNumeric = type === "numeric";
  const isBar = type === "bar";
  const isDoughnut = type === "doughnut";
  const isLinear = type === "linear";
  const isTime = type === "time";

  const h = isNumeric ? 1 : isBar || isDoughnut || isLinear ? 2 : 1;
  const w = isNumeric ? 1 : isBar || isDoughnut || isLinear || isTime ? 2 : 1;
  const lastX =
    widgetsLayout.layout.length > 0
      ? widgetsLayout.layout.sort((a, b) => b.x - a.x)[0].x +
        widgetsLayout.layout.sort((a, b) => b.x - a.x)[0].w
      : 0;
  const lastY =
    widgetsLayout.layout.length > 0
      ? widgetsLayout.layout.sort((a, b) => b.x - a.x)[0].y +
        widgetsLayout.layout.sort((a, b) => b.x - a.x)[0].h
      : 0;
  const approveX = w === 1 ? lastX < 6 : lastX < 5;
  const x = widgetsLayout.layout.length > 0 && approveX ? lastX : 0;
  const y =
    widgetsLayout.layout.length > 0 && approveX
      ? widgetsLayout.layout.sort((a, b) => b.x - a.x)[0].y
      : lastY;
  const color: { color: "blue" | "pink" | null } | null =
    isBar || isLinear ? { color: "blue" } : null;

  const defaultFormValue = {
    agent_uuid,
    type,
    title:
      isLinear || isBar
        ? "Звонки"
        : isTime
        ? "Средняя длительность разговора"
        : "",
    w: w,
    h: h,
    x: x,
    y: y,
    static: false,
    filters: {},
    ...color,
  };
  return defaultFormValue;
};

const widgetDescription = {
  uniqueClients: `Уникальные клиенты — это количество
номеров телефона без повторений.
Включите данную опцию и узнайте,
сколько в среднем требуется времени
для обслуживания одного клиента.`,
  averageCallDuration: `Виджет покажет среднюю длительность разговора
среди звонков со статусом телефонии «200 ОК».
Используйте фильтры, чтобы настроить 
особые параметры виджета.`,
  pieChartWidget: `Виджет «Круговая диаграмма» покажет количественное
соотношение звонков по значениям выбранной метрики.
Используйте фильтры, чтобы настроить особые
параметры виджета.`,
  columnChartWidget: `Виджет «Столбчатая диаграмма» покажет
количество всех звонков по агенту.
Используйте фильтры, чтобы настроить 
особые параметры виджета.`,
  lineChartWidget: `Виджет «Линейная диаграмма» покажет
количество всех звонков по агенту.
Используйте фильтры, чтобы настроить
особые параметры виджета.`,
  metricWidget: `Виджет «Показатель» покажет количество
звонков с выбранным значением метрики.
Используйте фильтры, чтобы настроить
особые параметры виджета.`,
};
const tooltipText = (type: string) => {
  switch (type) {
    case "doughnut":
      return widgetDescription.pieChartWidget;
    case "numeric":
      return widgetDescription.metricWidget;
    case "bar":
      return widgetDescription.columnChartWidget;
    case "linear":
      return widgetDescription.lineChartWidget;
    case "time":
      return widgetDescription.averageCallDuration;
    case "uniq_time":
      return widgetDescription.uniqueClients;
    default:
      return null;
  }
};
const tooltipWidth = (type: string) => {
  switch (type) {
    case "doughnut":
      return 341;
    case "numeric":
      return 260;
    case "bar":
      return 251;
    case "linear":
      return 244;
    case "time":
      return 301;
    case "uniq_time":
      return 240;
    default:
      return null;
  }
};

export const getAllFiltersSizes = (
  allFilters: IFiltersResponce
): { [key: string]: number } => {
  const result = {} as { [key: string]: number };
  for (let label in allFilters) {
    result[label] = allFilters[label].length - 1;
  }
  return result;
};

const mergeFiltersWithLabel = (
  selectedFilters: IFiltersResponce,
  allFiltersSizes: { [key: string]: number },
  isForceTranslate: boolean = false
) => {
  const filterCollection = { ...selectedFilters };

  for (let label in filterCollection) {
    if (filterCollection.hasOwnProperty(label)) {
      const selectedFiltersLength = filterCollection[label].length;
      const allFiltersSize = allFiltersSizes[label];

      if (allFiltersSize === selectedFiltersLength) {
        filterCollection[label] = [`${russifier(label)}: выбраны все значения`];
      } else {
        const filterString = filterCollection[label]
          .map((x) => (x === null || x === "null" ? ALIAS_FOR_NULL : x))
          .join(", ");
        filterCollection[label] = [
          `${russifier(label)}: ${forceTranslateString(
            filterString,
            isForceTranslate
          ).toLowerCase()}`,
        ];
      }
    }
  }

  return filterCollection;
};

const defaultLineWidgetData = [11000, 9500, 18000, 15500, 19500, 9000, 6500];
export const createWidgetModalConfig = {
  titleFormatter,
  getLastWeek,
  filtersNamesFormatter,
  filterValuesFormatter,
  defaultLineWidgetData,
  MetricNamesFormatter,
  metricValuesFormatter,
  initialWidgetFormFormatter,
  tooltipText,
  tooltipWidth,
  mergeFiltersWithLabel,
  getAllFiltersSizes,
};
