import { formatQueryAsArray, formatStrictNameString } from "@features/config";
import { createApi } from "@reduxjs/toolkit/query/react";
import { HYDRATE } from "next-redux-wrapper";
import { baseQueries } from "@features/baseQueries";
import {
  GetReportsResultsArray,
  ReportsRequestInterface,
  GetReportStatusesInterface,
  DeleteReportInterface,
  IAgentsResponce,
  CreateReportResponseInterface,
  CreateReportRequestInterface,
} from "../types";

const { fetchBaseQueryWithRefresh } = baseQueries;

export const reportsApi = createApi({
  reducerPath: "reportsApi",
  baseQuery: fetchBaseQueryWithRefresh,
  extractRehydrationInfo(action, { reducerPath }) {
    if (action.type === HYDRATE) {
      return action.payload[reducerPath];
    }
  },
  tagTypes: ["Reports"],
  endpoints: (builder) => ({
    getReports: builder.query<
      IAgentsResponce<GetReportsResultsArray[]>,
      ReportsRequestInterface
    >({
      providesTags: (result) =>
        result?.results
          ? [
              ...result?.results.map(({ id }) => ({
                type: "Reports" as const,
                id,
              })),
              { type: "Reports", id: "LIST" },
            ]
          : [{ type: "Reports", id: "LIST" }],
      query({ ordering, page, page_size, search, agent_uuid, statuses }) {
        return `/api/agents/${agent_uuid}/reports/${formatStrictNameString({
          ordering,
          page,
          page_size,
          search,
        })}${statuses && statuses.length > 0 ? `&status=${statuses}` : ""}`;
      },
    }),
    getReportsStatuses: builder.query<GetReportStatusesInterface[], void>({
      query() {
        return `/api/report-statuses/`;
      },
    }),
    createReport: builder.mutation<
      CreateReportResponseInterface,
      CreateReportRequestInterface
    >({
      invalidatesTags: [{ type: "Reports", id: "LIST" }],
      query: ({
        id,
        start_date,
        end_date,
        filters,
        queue_uuid,
        report_name,
        report_type,
      }) => {
        return {
          url: `/api/agents/${id}/create_report/${formatQueryAsArray({
            //@ts-expect-error
            queue_uuid: queue_uuid,
            ...filters,
          })}`,
          method: "POST",
          params: {
            start: start_date,
            end: end_date,
          },
          body: {
            report_name,
            report_type,
          },
        };
      },
    }),
    deleteReport: builder.mutation<void, DeleteReportInterface>({
      invalidatesTags: [{ type: "Reports", id: "LIST" }],
      query: ({ uuid, id }) => {
        return {
          url: `/api/agents/${uuid}/reports/${id}/`,
          method: "DELETE",
        };
      },
    }),
  }),
});

export const {
  useGetReportsQuery,
  useGetReportsStatusesQuery,
  useDeleteReportMutation,
  useCreateReportMutation,
} = reportsApi;
