import {
  BaseQueryApi,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
  fetchBaseQuery,
} from "@reduxjs/toolkit/dist/query";
import { QueryReturnValue } from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import { MaybePromise } from "@reduxjs/toolkit/dist/query/tsHelpers";
import { Mutex } from "async-mutex";
import { RootState } from "@store";
import {
  selectRefreshToken,
  setAccessToken,
  setCredentials,
  setTokenError,
} from "./auth/auth-slice";
import { RolesEnum } from "@constants";

const mutex = new Mutex();

const fetchBaseWithoutRefreshProps = {
  baseUrl: process.env.NEXT_PUBLIC_API_URL
    ? process.env.NEXT_PUBLIC_API_URL
    : "",

  prepareHeaders: (headers: Headers, { getState }: any) => {
    const token = (getState() as RootState).auth.access;
    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }
    return headers;
  },
};

const baseQuery = fetchBaseQuery(fetchBaseWithoutRefreshProps);

const fetchBaseQueryWithRefresh: (
  args: string | { url: string; method: string; body?: any },
  api: BaseQueryApi,
  extraOptions: {}
) => MaybePromise<
  QueryReturnValue<any, FetchBaseQueryError, FetchBaseQueryMeta>
> = async (args, api, extraOptions) => {
  await mutex.waitForUnlock();
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      try {
        const refreshResult: MaybePromise<
          QueryReturnValue<any, FetchBaseQueryError, FetchBaseQueryMeta>
        > = await baseQuery(
          {
            url: "/api/auth/jwt/refresh/",
            method: "POST",
            body: {
              refresh: selectRefreshToken(api.getState() as RootState),
            },
          },
          api,
          extraOptions
        );
        if (refreshResult.data) {
          api.dispatch(setAccessToken({ access: refreshResult.data?.access }));
          result = await baseQuery(args, api, extraOptions);
        } else {
          api.dispatch(
            setCredentials({
              refresh: "",
              access: "",
              role: RolesEnum.BASE_PRIVILEGES,
            })
          );
          api.dispatch(setTokenError(refreshResult.error));
        }
      } finally {
        release();
      }
    } else {
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }
  return result;
};

export const baseQueries = {
  baseQuery,
  fetchBaseQueryWithRefresh,
};
