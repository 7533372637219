import styled from "styled-components";
import ellipse from "@assets/ellipse.svg";

const StyledLoader = styled(ellipse)`
  margin: auto;
  animation: 1s linear 0s normal none infinite running rot;
  @keyframes rot {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const Loader = () => {
  return (
    <>
      <StyledLoader />
    </>
  );
};

export const CenteredLoader = () => (
  <div
    style={{
      display: "flex",
      height: "80vh",
    }}
  >
    <Loader />
  </div>
);
