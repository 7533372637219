import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { HYDRATE } from "next-redux-wrapper";
import { RootState } from "@store";
import {
  AuthProtectedResponseInterface,
  AuthVerifyRequestInterface,
  LoginRequest,
  UserResponse,
} from "@features/types";

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.NEXT_PUBLIC_API_URL || "",
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth.access;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  extractRehydrationInfo(action, { reducerPath }) {
    if (action.type === HYDRATE) {
      return action.payload[reducerPath];
    }
  },
  endpoints: (builder) => ({
    login: builder.mutation<UserResponse, LoginRequest>({
      query: (credentials) => {
        return {
          url: "/api/auth/jwt/create/",
          method: "POST",
          body: credentials,
        };
      },
    }),
    verify: builder.mutation<string, AuthVerifyRequestInterface>({
      query: (token) => ({
        url: "/api/auth/jwt/verify/",
        method: "POST",
        body: token,
      }),
    }),
    refresh: builder.mutation<UserResponse, string | null>({
      query: (refresh) => ({
        url: "/api/auth/jwt/refresh/",
        method: "POST",
        body: {
          refresh: refresh,
        },
      }),
    }),
    checkToken: builder.mutation<UserResponse, { uid: string; token: string }>({
      query: (body) => ({
        url: "/api/auth/check_token/",
        method: "POST",
        body: body,
      }),
    }),
    protected: builder.mutation<AuthProtectedResponseInterface, void>({
      query: () => "protected",
    }),
  }),
});

export const {
  useLoginMutation,
  useVerifyMutation,
  useProtectedMutation,
  useRefreshMutation,
  useCheckTokenMutation,
} = authApi;
