import React, { FC } from "react";
import { useAppSelector } from "@helpers/hooks/redux";
import { selectUserRole } from "@features/auth/auth-slice";
import { RolesEnum } from "@constants";
import ErrorPageLayout from "@components/screens/404";
import useSafeRouter from "@helpers/hooks/useSafeRouter";

const Error404Page: FC = () => {
  const { push } = useSafeRouter();
  const role = useAppSelector((state) => selectUserRole(state));

  const goToHome = () => {
    if (role === RolesEnum.ALL_PRIVILEGES) {
      push(`/`);
    } else {
      push(`/agents`);
    }
  };
  return <ErrorPageLayout page="404Error" onClick={goToHome} />;
};

export default Error404Page;
