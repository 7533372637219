import { ReactNode, useEffect, useRef, useState } from "react";
import {
  useDesktopMediaQuery,
  useTabletAndBelowMediaQuery,
} from "@helpers/hooks/apaptive";
import ScrollContainer from "react-indiana-drag-scroll";
import { WrapperStyles } from "./styles";

const { BlurBefore, BlurAfter, StyledScrollContainer } = WrapperStyles;

export const Wrapper = ({
  grab,
  children,
  isBlur = false,
  isChangeColor = false,
  offsetAfterX,
  heightOfContainer,
}: {
  grab: boolean;
  children: ReactNode;
  isBlur?: boolean;
  isChangeColor?: boolean;
  offsetAfterX?: string;
  heightOfContainer?: string;
}) => {
  const [blurBefore, setBlurBefore] = useState(false);
  const [blurAfter, setBlurAfter] = useState(false);
  const [scrollContainerWidth, setScrollContainerWidth] = useState(false);
  const [isGrabCursor, setIsGrabCursor] = useState(false);
  const [blurColor, setBlurColor] = useState("250, 250, 251");
  const scrollContainerRef = useRef<ScrollContainer>(null);
  const isTabletOrMobile = useTabletAndBelowMediaQuery();
  const isDesktop = useDesktopMediaQuery();

  useEffect(() => {
    if (scrollContainerRef?.current) {
    }
    setScrollContainerWidth(
      // @ts-expect-error
      scrollContainerRef?.current?.container.current.firstChild.clientWidth
    );
  }, [scrollContainerRef?.current]);

  useEffect(() => {
    if (
      isBlur &&
      scrollContainerRef?.current &&
      scrollContainerWidth &&
      scrollContainerWidth >
        // @ts-expect-error
        scrollContainerRef.current?.container?.current?.clientWidth
    ) {
      setIsGrabCursor(true);
      setBlurAfter(true);
    } else {
      setIsGrabCursor(false);
      setBlurAfter(false);
    }
  }, [scrollContainerRef?.current, scrollContainerWidth]);

  const BlurContainer = () => {
    if (blurColor === "250, 250, 251" && isChangeColor && isDesktop)
      setBlurColor("243, 243, 255");
    if (scrollContainerRef && scrollContainerRef.current) {
      const containerElement = scrollContainerRef.current;
      // @ts-expect-error
      if (containerElement.scrollLeft !== 0) {
        setBlurBefore(true);
      } else {
        setBlurBefore(false);
      }
      if (scrollContainerWidth) {
        const widthOfContainer =
          // @ts-expect-error
          containerElement?.container.current.clientWidth;
        // @ts-expect-error
        const scrollEnd = scrollContainerWidth - widthOfContainer;
        // @ts-expect-error
        if (scrollEnd === containerElement.scrollLeft) {
          setBlurAfter(false);
        } else {
          setBlurAfter(true);
        }
      }
    }
  };

  const setAnotherBlurColor = () => {
    if (isChangeColor && isDesktop) setBlurColor("243, 243, 255");
  };

  if (!grab) return <>{children}</>;
  return (
    <>
      <BlurBefore
        height={heightOfContainer}
        opacity={blurBefore ? 1 : 0}
        blurColor={blurColor}
      />
      <StyledScrollContainer
        vertical
        isGrab={!isBlur ? true : isGrabCursor}
        className="scroll-container"
        onScroll={isBlur ? BlurContainer : () => {}}
        onStartScroll={() => setAnotherBlurColor()}
        onEndScroll={() => setBlurColor("250, 250, 251")}
        onMouseDown={() => setAnotherBlurColor()}
        ref={scrollContainerRef}
      >
        {children}
      </StyledScrollContainer>
      <BlurAfter
        right={offsetAfterX}
        height={heightOfContainer}
        opacity={blurAfter ? 1 : 0}
        blurColor={blurColor}
        isTabletOrMobile={isTabletOrMobile}
      />
    </>
  );
};
