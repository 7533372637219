import { reportsApi } from "./reports-api-slice";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "@store";
import { StatusesType, GetReportStatusesInterface } from "@features/types";

const initialStatuses: StatusesType = {
  statuses: [],
};

const slice = createSlice({
  name: "reports",
  initialState: initialStatuses,
  reducers: {
    setStatusDate: (
      state,
      { payload }: PayloadAction<Array<GetReportStatusesInterface> | []>
    ) => {
      state.statuses = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      reportsApi.endpoints.getReportsStatuses.matchFulfilled,
      (state, { payload }) => {
        state.statuses = payload;
      }
    );
  },
});
export const { setStatusDate } = slice.actions;

export default slice.reducer;

export const selectReportStatuses = (state: RootState) =>
  state.reports.statuses;
