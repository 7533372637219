import { useEffect, useMemo, useRef, useState } from "react";
import type { AppProps } from "next/app";
import Head from "next/head";
import { useDispatch, useStore } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import styled, { ThemeProvider } from "styled-components";
import NextNProgress from "nextjs-progressbar";
import { wrapper } from "@store";
import { useAppSelector } from "@helpers/hooks/redux";
import { isValidRole, pathFormatter, tableRowFormatter } from "@utils";
import { availiblePages, availibleTabs } from "@constants";
import {
  selectAccessToken,
  selectRefreshToken,
  selectTokenError,
  selectUserRole,
  setCredentials,
  setUserRole,
} from "@features/auth/auth-slice";
import { useMeQuery } from "@features/user/user-api-slice";
import {
  clearProfile,
  setCroppedAvatar,
  setAuth,
  setAvatar,
} from "@features/user/user-slice";
import {
  useRefreshMutation,
  useVerifyMutation,
} from "@features/auth/auth-api-slice";
import { useGetLabelsQuery } from "@features/labels/labels-api-slice";
import { useGetColorsQuery } from "@features/agents/agents-api-slice";
import { setColors } from "@features/agents/agents-slice";
import { setLabelsData } from "@features/labels/labels-slice";

import { Flex, Loader, Toast, errorNotify } from "@new-ui-kit";
import { Color, TUserRole } from "@features/types";
import { light as LightTheme, GlobalStyle } from "@styles/index";
import "react-toastify/dist/ReactToastify.min.css";
import Error404Page from "./404";
import { Noto_Sans } from "next/font/google";
import { Chart } from "chart.js";
import useSafeRouter from "@helpers/hooks/useSafeRouter";

const Main = styled.main`
  scrollbar-gutter: stable;
`;

const notoSans = Noto_Sans({
  weight: ["400", "500", "600", "700"],
  subsets: ["latin", "cyrillic"],
  display: "swap",
});

// set default font for our graphics;
Chart.defaults.font.family = notoSans.style.fontFamily;

function MyApp({ Component, pageProps }: AppProps) {
  const store = useStore();
  const dispatch = useDispatch();
  const { query, pathname, push, replace } = useSafeRouter();

  const [isLoading, setIsLoading] = useState(true);
  const [isCustomLoading, setIsCustomLoading] = useState(false);
  const [authorised, setAuthorised] = useState(false);
  const [tableRows, setTableRows] = useState(9);
  const [isAvailiblePage, setIsAvailiblePage] = useState(false);
  const [isAvailibleTab, setIsAvailibleTab] = useState(true);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const [firstRender, setFirstRender] = useState(true);
  const [myTimeout, setMyTimeout] = useState(false);
  const pathArr = pathname.split("/");
  const path = useMemo(() => pathArr[pathArr.length - 1], [pathArr]);
  const pagePath = pathArr[1];
  const agentTab = query?.tab;

  const [verify] = useVerifyMutation();
  const [refresh] = useRefreshMutation();

  const { data: labelsData } = useGetLabelsQuery(
    {},
    { skip: !authorised || pagePath !== "agents" }
  );
  const { data: colorsData, refetch: refetchColors } = useGetColorsQuery({});

  const {
    data: userData,
    isLoading: isUserLoading,
    isError,
    error: errorData,
  } = useMeQuery({}, { skip: !authorised });

  const token = useAppSelector((state) => selectAccessToken(state));
  const refreshToken = useAppSelector((state) => selectRefreshToken(state));
  const role: TUserRole = useAppSelector((state) => selectUserRole(state));
  const tokenError = useAppSelector((state) => selectTokenError(state));

  useEffect(() => {
    if (!token) {
      // clutch for check auth
      refetchColors();
    }
  }, [pagePath, refetchColors, token]);

  useEffect(() => {
    if (!userData && isError && !isUserLoading) {
      if ("status" in errorData && errorData.status === 429) {
        errorNotify(
          "Ошибка при получении данных",
          "Превышен лимит запросов. Пожалуйста, повторите попытку через несколько минут.",
          "getCallsErrorLimit"
        );
      } else {
        errorNotify(
          "Ошибка",
          "Что-то пошло не так, пожалуйста повторите снова",
          "getCallsError"
        );
      }
    }
  }, [userData, isError, isUserLoading, errorData]);

  useEffect(() => {
    if (
      (pagePath === "" ||
        pagePath === "agents" ||
        pagePath === "support" ||
        pagePath === "settings" ||
        pagePath === "auth") &&
      role &&
      isValidRole(role)
    ) {
      const availiblePagesForUser = availiblePages[role];
      if (availiblePagesForUser[pagePath]) {
        setIsAvailiblePage(true);
      }
      if (!availiblePagesForUser[pagePath]) {
        if (pagePath === "") {
          goOnAgents();
        } else {
          setIsAvailiblePage(false);
        }
      }
    }
  }, [pagePath, role]);

  const goOnAgents = () => {
    setIsCustomLoading(true);
    replace("/agents").then(() => {
      setIsCustomLoading(false);
    });
  };

  useEffect(() => {
    if (
      pagePath === "agents" &&
      pathArr[2] === "[agentPage]" &&
      agentTab &&
      role
    ) {
      const availibleTabsForRole = availibleTabs[role];

      if (
        pagePath === "agents" &&
        (agentTab === "dashboard" ||
          agentTab === "calls" ||
          agentTab === "sessions" ||
          agentTab === "reports") &&
        availibleTabsForRole[agentTab]
      ) {
        setIsAvailibleTab(true);
      } else {
        setIsAvailibleTab(false);
      }
    } else {
      setIsAvailibleTab(true);
    }
  }, [agentTab, pagePath, role]);

  const exit = () => {
    dispatch(setAuth({ isAuth: false }));
    dispatch(
      setCredentials({
        refresh: "",
        access: "",
        role: null,
      })
    );
    dispatch(clearProfile());
    push({
      pathname: "/auth",
      query: {},
    });
    setIsLoading(false);
  };

  const setColorsHandler = (colorsData: Array<Color>) => {
    dispatch(setColors({ colors: colorsData }));
  };

  const setTableRowsHandler = (height: number) => {
    const rows = tableRowFormatter(height);
    setTableRows(rows);
  };
  useEffect(() => {
    if (containerRef && containerRef.current) {
      setTableRowsHandler(containerRef.current?.clientHeight);
    }
  }, [
    containerRef,
    containerRef.current,
    containerRef.current?.clientHeight,
    firstRender,
  ]);

  useEffect(() => {
    if (colorsData && authorised) {
      setColorsHandler(colorsData);
    }
  }, [colorsData, authorised]);

  useEffect(() => {
    if (userData?.account_type)
      dispatch(setUserRole({ role: userData.account_type }));
  }, [userData?.account_type]);

  useEffect(() => {
    if (userData?.avatar) dispatch(setAvatar({ avatar: userData.avatar }));
  }, [userData?.avatar]);

  useEffect(() => {
    if (labelsData && authorised) {
      dispatch(setLabelsData(labelsData));
    }
  }, [labelsData, authorised]);

  useEffect(() => {
    if (firstRender) {
      setTimeout(() => {
        setMyTimeout(true);
        setFirstRender(false);
      }, 1);
    }
  }, [firstRender]);

  useEffect(() => {
    if (myTimeout) {
      checkToken();
    }
    if (tokenError) {
      checkToken();
    }
  }, [myTimeout, token, tokenError]);

  useEffect(() => {
    dispatch(setCroppedAvatar());
  }, [userData?.avatar, userData?.avatar_meta]);

  const checkToken = () => {
    if (token) {
      verify({ token: token })
        .unwrap()
        .then(() => {
          dispatch(setAuth({ isAuth: true }));
          setAuthorised(true);
          setIsLoading(false);
          dispatch(
            setUserRole({
              role: userData?.account_type || null,
            })
          );
        })
        .catch(() => {
          if (refreshToken) {
            refresh(refreshToken)
              .unwrap()
              .then(() => {
                dispatch(setAuth({ isAuth: true }));
                setAuthorised(true);
                setIsLoading(false);
                dispatch(
                  setUserRole({
                    role: userData?.account_type || null,
                  })
                );
              })
              .catch(() => {
                exit();
              });
          } else {
            exit();
          }
        });
    } else {
      if (pathname.slice(0, 5) === "/auth") {
        setIsLoading(false);
      } else {
        exit();
      }
    }
  };

  return (
    <ThemeProvider theme={LightTheme}>
      {/* @ts-expect-error */}
      <PersistGate persistor={store.__persistor} loading={null}>
        <Head>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, maximum-scale=5, user-scalable=yes"
          ></meta>
          <title>Fromtech | {pathFormatter(path, query)}</title>
        </Head>
        <style jsx global>{`
          html {
            font-family: ${notoSans.style.fontFamily} !important;
            --toastify-font-family: ${notoSans.style.fontFamily} !important;
          }
        `}</style>
        <Main ref={containerRef}>
          <NextNProgress
            color={LightTheme.main_violet}
            startPosition={0.3}
            stopDelayMs={200}
            height={4}
            showOnShallow={true}
            options={{ showSpinner: false }}
          />

          {isLoading ||
          isCustomLoading ||
          (!token && pathname.slice(0, 5) !== "/auth") ? (
            <Flex
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              height="100%"
              width="100%"
            >
              <Loader />
            </Flex>
          ) : (isAvailiblePage && isAvailibleTab) || pagePath === "auth" ? (
            <Component tableRows={tableRows} {...pageProps} />
          ) : (
            <Error404Page />
          )}
          <Toast />
        </Main>
      </PersistGate>
      <GlobalStyle />
    </ThemeProvider>
  );
}

export default wrapper.withRedux(MyApp);
