import { DefaultTheme } from "styled-components";

const theme: DefaultTheme = {
  id: "dark",
  main_violet: "#4F46E5",
  lilac: "#E8E7FF",
  lilac_light: "#F3F3FF",
  white: "#FFFFFF",
  bg: {
    main: "#FAFAFB",
    cards: "#FFFFFF",
  },
  border: {
    sidebar: "#e4e4e7",
  },
  text: {
    purple: "#4F46E5",
    black: "#111827",
    dark_gray: "#636366",
    light_gray: "#A4A4A5",
    pink: "#DB5E88",
    green: "#64C66F",
    orange: "#EAA43A",
    white: "#FFFFFF",
    error: "#EA0000",
    success: "#3EC91C",
    validation: "#FFB628",
    disabled: "#E4E4E7",
  },
  btn: {
    delete: {
      default: {
        bg: "#EA0000",
        text: "#FFFFFF",
        border: "transparent",
        borderWidth: "0px",
      },
      hover: {
        bg: "#D90000",
        text: "#FFFFFF",
        border: "transparent",
        borderWidth: "0px",
      },
      pressed: {
        bg: "#220000",
        text: "#11FFFF",
        border: "transparent",
        borderWidth: "0px",
      },
      disabled: {
        bg: "#E4E4E7",
        text: "#A4A4A5",
        border: "transparent",
        borderWidth: "0px",
      },
    },
    primary: {
      default: {
        bg: "#4F46E5",
        text: "#FFFFFF",
        border: "transparent",
        borderWidth: "0px",
      },
      hover: {
        bg: "#473ECF",
        text: "#FFFFFF",
        border: "transparent",
        borderWidth: "0px",
      },
      pressed: {
        bg: "#3B34B8",
        text: "#FFFFFF",
        border: "transparent",
        borderWidth: "0px",
      },
      disabled: {
        bg: "#E4E4E7",
        text: "#A4A4A5",
        border: "transparent",
        borderWidth: "0px",
      },
    },
    secondary: {
      default: {
        bg: "#FFFFFF",
        text: "#4F46E5",
        border: "#4F46E5",
        borderWidth: "1px",
      },
      hover: {
        bg: "#F3F3FF",
        text: "#4F46E5",
        border: "#4F46E5",
        borderWidth: "1px",
      },
      pressed: {
        bg: "#E8E7FF",
        text: "#4F46E5",
        border: "#4F46E5",
        borderWidth: "1px",
      },
      disabled: {
        bg: "#FFFFFF",
        text: "#A4A4A5",
        border: "#E4E4E7",
        borderWidth: "1px",
      },
    },
    tertiary: {
      default: {
        bg: "transparent",
        text: "#4F46E5",
        border: "transparent",
        borderWidth: "0px",
      },
      hover: {
        bg: "#F3F3FF",
        text: "#4F46E5",
        border: "transparent",
        borderWidth: "0px",
      },
      pressed: {
        bg: "#E8E7FF",
        text: "#4F46E5",
        border: "#4F46E5",
        borderWidth: "0px",
      },
      disabled: {
        bg: "transparent",
        text: "#A4A4A5",
        border: "transparent",
        borderWidth: "0px",
      },
    },
    sidebar: {
      default: {
        bg: "transparent",
        text: "#111827",
        border: "transparent",
        borderWidth: "0px",
      },
      hover: {
        bg: "#F3F3FF",
        text: "#111827",
        border: "transparent",
        borderWidth: "0px",
      },
      pressed: {
        bg: "#E8E7FF",
        text: "#4F46E5",
        border: "#4F46E5",
        borderWidth: "0px",
      },
      disabled: {
        bg: "transparent",
        text: "#A4A4A5",
        border: "transparent",
        borderWidth: "0px",
      },
    },
    dropdown: {
      default: {
        bg: "transparent",
        text: "#111827",
        border: "transparent",
        borderWidth: "0px",
      },
      hover: {
        bg: "#F3F3FF",
        text: "#111827",
        border: "transparent",
        borderWidth: "0px",
      },
      pressed: {
        bg: "#E8E7FF",
        text: "#4F46E5",
        border: "#4F46E5",
        borderWidth: "0px",
      },
      disabled: {
        bg: "transparent",
        text: "#A4A4A5",
        border: "transparent",
        borderWidth: "0px",
      },
    },
    link: {
      default: {
        bg: "transparent",
        text: "#4F46E5",
        border: "transparent",
        borderWidth: "1px",
      },
      hover: {
        bg: "transparent",
        text: "#473ECF",
        border: "transparent",
        borderWidth: "1px",
      },
      pressed: {
        bg: "transparent",
        text: "#3B34B8",
        border: "transparent",
        borderWidth: "1px",
      },
      disabled: {
        bg: "transparent",
        text: "#E4E4E7",
        border: "transparent",
        borderWidth: "1px",
      },
    },
    deleteSecondary: {
      default: {
        bg: "transparent",
        text: "#DD0202",
        border: "transparent",
        borderWidth: "1px",
      },
      hover: {
        bg: "transparent",
        text: "#DD0202",
        border: "transparent",
        borderWidth: "1px",
      },
      pressed: {
        bg: "transparent",
        text: "#DD0202",
        border: "transparent",
        borderWidth: "1px",
      },
      disabled: {
        bg: "transparent",
        text: "#A4A4A5",
        border: "transparent",
        borderWidth: "1px",
      },
    },
  },
  form: {
    default: {
      bg: "#FFFFFF",
      text: "#636366",
      text_dark: "#111827",
      border: "#E4E4E7",
      shadow: "none",
    },
    hover: {
      bg: "#FFFFFF",
      text: "#636366",
      text_dark: "#111827",
      border: "#4F46E5",
      shadow: "none",
    },
    filledWithHover: {
      bg: "#E8E7FF",
      text: "#636366",
      text_dark: "#111827",
      border: "#E8E7FF",
      shadow: "none",
    },
    focused: {
      bg: "#FFFFFF",
      text: "#636366",
      text_dark: "#111827",
      border: "#4F46E5",
      shadow: "0px 0px 5px rgba(79, 70, 229, 0.5)",
    },
    filled: {
      bg: "#E8E7FF",
      text: "#636366",
      text_dark: "#111827",
      border: "#E8E7FF",
      shadow: "none",
    },
    disabled: {
      bg: "#FFFFFF",
      text: "#E4E4E7",
      text_dark: "#E4E4E7",
      border: "#E4E4E7",
      shadow: "none",
    },
    errorDefault: {
      bg: "#FFFFFF",
      text: "#636366",
      text_dark: "#111827",
      border: "#FF9696",
      shadow: "none",
    },
    validation: {
      bg: "#FFFFFF",
      text: "#636366",
      text_dark: "#111827",
      border: "#FFE6B5",
      shadow: "none",
    },
    success: {
      bg: "#FFFFFF",
      text: "#636366",
      text_dark: "#111827",
      border: "#E4E4E7",
      shadow: "none",
    },
    description: "#636366",
  },
  graph: {
    violet_light: "#AAACFB",
    violet: "#4F46E5",
    purple_light: "#D58CF8",
    purple: "#B416FF",
    pink_light: "#F7B7CD",
    pink: "#DB5E88",
    blue_light: "#A6C7F4",
    blue: "#65A2F8",
    green_light: "#9CEC95",
    green: "#64C66F",
    orange_light: "#FCCD86",
    orange: "#EAA43A",
  },
  stroke: {
    default: "#E4E4E7",
    hover: "#4F46E5",
  },
};

export default theme;
