import styled from "styled-components";

export interface IColumn {
  gap?: number;
  centered?: boolean;
  fullWidth?: boolean;
  width?: string;
  margin?: string;
  padding?: string;
  between?: boolean;
  fullHeight?: boolean;
  maxWidth?: string;
  height?: string;
  justifyContent?: string;
}

export const Column = styled.div<IColumn>`
  display: flex;
  flex-direction: column;
  width: ${({ fullWidth, width }) =>
    fullWidth ? "100%" : width ? width : "initial"};
  align-items: ${({ centered }) => (centered ? "center" : "unset")};
  justify-content: ${({ justifyContent, centered, between }) =>
    justifyContent
      ? justifyContent
      : centered
      ? "center"
      : between
      ? "space-between"
      : "unset"};
  row-gap: ${({ gap }) => (gap ? `${gap}px` : 0)};
  margin: ${({ margin }) => margin || 0};
  padding: ${({ padding }) => padding || 0};
  height: ${({ fullHeight }) => fullHeight && "100%"};
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : null)};
  height: ${({ height }) => height};
`;
