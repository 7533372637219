import { useRouter, NextRouter } from "next/router";
import { useEffect, useState } from "react";

type PushMethod = NextRouter["push"];
type ReplaceMethod = NextRouter["replace"];

const useSafeRouter = () => {
  const [onChanging, setOnChanging] = useState(false);
  const handleRouteChange = () => {
    setOnChanging(false);
  };
  const router = useRouter();
  // safePush is used to avoid route pushing errors when users click multiple times or when the network is slow:  "Error: Abort fetching component for route"

  const safePush: PushMethod = (pushArgs) => {
    if (onChanging) {
      // return;
      return Promise.resolve(false);
    }
    setOnChanging(true);
    return router.push(pushArgs);
  };

  const safeReplace: ReplaceMethod = (replaceArgs) => {
    if (onChanging) {
      // return;
      return Promise.resolve(false);
    }
    setOnChanging(true);
    return router.replace(replaceArgs);
  };

  useEffect(() => {
    router.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router, setOnChanging]);
  return {
    push: safePush,
    replace: safeReplace,
    query: router.query,
    pathname: router.pathname,
  };
};

export default useSafeRouter;
