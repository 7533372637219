import Link from "next/link";
import Logo from "@public/main_logo.svg";
import React, { FC, memo } from "react";
import { useAppSelector } from "@helpers/hooks/redux";
import { selectTokenError, selectUserRole } from "@features/auth/auth-slice";
import { RolesEnum } from "@constants";
import styled from "styled-components";
import breakpoints from "@styles/breakpoints";
import Image from "next/image";
import { useDesktopMediaQuery } from "@helpers/hooks/apaptive";
import { If, Then } from "react-if";

const StyledLink = styled(Link)`
  height: 48px;
  @media (${breakpoints.tablet}) {
    max-width: 150px;
    max-height: 28px;
  }
`;

type Props = {
  isNotFoundPage?: boolean;
};

const MainLogo: FC<Props> = ({ isNotFoundPage }) => {
  const role = useAppSelector((state) => selectUserRole(state));
  const tokenError = useAppSelector((state) => selectTokenError(state));
  const isDesktop = useDesktopMediaQuery();

  return (
    <StyledLink
      aria-label="home"
      href={
        tokenError
          ? "/auth"
          : role === RolesEnum.ALL_PRIVILEGES
          ? "/"
          : "/agents"
      }
    >
      <If
        condition={
          (isDesktop && !isNotFoundPage) || (!isDesktop && isNotFoundPage)
        }
      >
        <Then>
          <Logo />
        </Then>
      </If>
      <If condition={isNotFoundPage && isDesktop}>
        <Then>
          <Image
            src={"/main_logo.svg"}
            width={244}
            height={60}
            alt={"Логотип"}
            priority
          />
        </Then>
      </If>
      <If condition={!isDesktop && !isNotFoundPage}>
        <Then>
          <Image
            src={"/main_logo.svg"}
            width={102}
            height={20}
            alt={"Логотип"}
            priority
          />
        </Then>
      </If>
    </StyledLink>
  );
};

export default memo(MainLogo);
