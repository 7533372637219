import { tokensApi } from "@features/tokens/tokens-api-slice";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "@store";
import { ITokenInfo } from "@features/types";
import { TTokensFilter } from "@components/Table/types";
import { TokenAction } from "@components/screens/settings/ApiPage/types";

interface ITokenSlice {
  tokens: ITokenInfo[];
  tokensFilter: TTokensFilter;
  tokenModalInfo: {
    token: ITokenInfo;
    isOpen: boolean;
    action: TokenAction;
  } | null;
}

const initialState: ITokenSlice = {
  tokens: [],
  tokensFilter: "-created_at",
  tokenModalInfo: null,
};

const slice = createSlice({
  name: "tokens",
  initialState,
  reducers: {
    setTokens: (state, { payload }: PayloadAction<ITokenInfo[]>) => {
      state.tokens = payload;
    },
    setTokensFilter: (state, { payload }: PayloadAction<TTokensFilter>) => {
      state.tokensFilter = payload;
    },
    deleteTokenLocal: (state, { payload }: PayloadAction<string>) => {
      const foundedTokenIndex = state.tokens.findIndex(
        (it) => it.uuid === payload
      );
      state.tokens.splice(foundedTokenIndex, 1);
    },
    setTokenModalInfo: (
      state,
      {
        payload,
      }: PayloadAction<{
        token: ITokenInfo;
        isOpen: boolean;
        action: TokenAction;
      } | null>
    ) => {
      if (payload) {
        state.tokenModalInfo = {
          token: payload.token,
          isOpen: payload.isOpen,
          action: payload.action,
        };
      } else {
        state.tokenModalInfo = null;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      tokensApi.endpoints.getTokens.matchFulfilled,
      (state, { payload }) => {
        state.tokens = payload;
      }
    );
    builder.addMatcher(
      tokensApi.endpoints.updateToken.matchFulfilled,
      (state, { payload }) => {
        const foundedTokenIndex = state.tokens.findIndex(
          (it) => it.uuid === payload.uuid
        );
        if (foundedTokenIndex !== -1) {
          state.tokens[foundedTokenIndex] = payload;
        }
      }
    );
    builder.addMatcher(
      tokensApi.endpoints.createToken.matchFulfilled,
      (state, { payload }) => {
        if (state.tokensFilter === "created_at") {
          state.tokens.push(payload);
        } else {
          state.tokens.unshift(payload);
        }
      }
    );
  },
});
export const {
  setTokens,
  setTokensFilter,
  deleteTokenLocal,
  setTokenModalInfo,
} = slice.actions;

export default slice.reducer;

export const selectTokensFilter = (state: RootState) =>
  state.tokens.tokensFilter;
export const selectTokens = (state: RootState) => state.tokens.tokens;

export const selectTokenModalInfo = (state: RootState) =>
  state.tokens.tokenModalInfo;
