import breakpoints from "@styles/breakpoints";
import { createGlobalStyle, css } from "styled-components";
import { Noto_Sans } from "next/font/google";

const notoSans = Noto_Sans({
  weight: ["400", "500", "600", "700"],
  subsets: ["latin", "cyrillic"],
  display: "swap",
});

export const scrollbarStyles = css`
  &::-webkit-scrollbar {
    height: 8px;
    width: 10px;
    background: white;
    border-radius: 15px;
  }

  &::-webkit-scrollbar-track {
    background: white;
    border-radius: 15px;
  }

  &::-webkit-scrollbar-corner {
    border-radius: 33px;
  }

  &::-webkit-scrollbar-thumb {
    background: #e4e4e7;
    border-radius: 15px;
    border: 2px solid white;
  }
  scrollbar-color: #e4e4e7 white;
  scrollbar-width: thin;
`;

const GlobalStyle = createGlobalStyle`
	*{padding: 0;margin: 0;border: 0;}
	*,*:before,*:after{box-sizing:border-box;}
	button{cursor: pointer; color: inherit; background: transparent; font-size: 16px}
	html,body{height:100%;width:100%;font-size:100%;}  
	html {
		scroll-behavior: smooth;
	}
	input,button,textarea,select{
		font-family:inherit;
		 outline: none;
		}
	
	ul{list-style: none;}
	img {display: block; max-width: 100%; height: auto;}

	a { 
		font-weight: 500; 
		font-size: inherit; 
		line-height: inherit;
		text-decoration: none;
		color: inherit;
	}

	html {  
		@media only screen and (max-width: 900px) {
			font-size: 70%;
		}
	}


	body {
		font-family: ${notoSans.style.fontFamily} !important;
		overflow-x: hidden;
		font-weight: 400 !important;
		font-size: 13px !important;
		line-height: 13px !important;
		color: ${({ theme }) => theme.text.black};
		background-color: ${({ theme }) => theme.bg.main};
		-webkit-font-smoothing: antialiased;

		.rs-picker-default .rs-btn {
			height: 48px;
			display: flex;
			align-items: center;
			border-radius: 10px;
			flex-direction: column;
			&::before{
				content: "Период";
				display: flex;
				position: relative;
				width: 100%;
				transition: 0.2s ease-out;
				align-items: center;
				line-height: 13px;
				font-weight: 400;
				background: transparent;
				color: #636366;
				top: -2px;
				font-size: 11px;
			}
		}
		.label-empty{
			.rs-btn-default::before{
				display: none !important;
			}
		}
		.rs-picker-date .rs-picker-toggle.rs-btn, .rs-picker-daterange .rs-picker-toggle.rs-btn{
			padding: 7px 46px 7px 16px;
			justify-content: center;
			align-items: flex-start;
		}

		.rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-clean, .rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-clean{
			top: 12px;
			width: 16px;
			right: 12px;
			font-size: 16px;
    		color: #636366;
		}
		.rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-caret, .rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-caret{
			top: 10px;
		}

		.rs-picker-toggle .rs-picker-toggle-placeholder {
			color: #636366;
			text-align: left;
			font-size: 16px;
		
			font-weight: 400;
		
		}
		.rs-picker-toggle-caret{
			height: auto;
		}
		.rs-picker-toggle-value{
			color: ${({ theme }) => theme.text.black} !important;
			font-size: 16px;
		}

		.rs-picker-daterange-menu .rs-calendar {
			min-width: 240px;
			width: 240px;
			padding: 16px 24px;
			@media ${breakpoints.tablet} {
				width: 330px;
			}
			.rs-calendar-body, .rs-calendar-header{
				padding: 0px;
				display: flex;
				flex-wrap: nowrap;
			}
		}
	}

	html, body {
		height: 100vh !important;
		scrollbar-gutter: stable;
	}
	
	html,
	body {
		margin: 0;
	}
	main {
		height: 100%;
	}
	


	#root {
		margin: 0 auto;
	}
	#__next {
		height: 100%;
	}
	.rs-picker-daterange-calendar-single{
		width: 100vw;
	}
	.rs-picker-daterange-panel-show-one-calendar{
		height: 100%;
	}
	.rs-picker-daterange-menu .rs-calendar{
		height: 100%;
		@media ${breakpoints.tablet} {
			height: 350px !important;
		}
		
	}
	.rs-picker-menu {
		background: #FFFFFF;
		border: 1px solid #E4E4E7;
		box-shadow: 0px 4px 16px #E4E4E7;
		border-radius: 10px;
		padding: 16px 0px 0px 0px !important;
		@media ${breakpoints.tablet} {
			border-radius: 0px;
			border: 0px;
			padding: 0px !important;
		}
		
	}
	.rs-calendar-table-cell-day{
		font-size: 14px;
		@media ${breakpoints.tablet} {
			font-size: 16px;
		}
	}
	.rs-picker-daterange-header {
		color: #636366;
	
		@media ${breakpoints.tablet} {
			justify-content: space-between;
			display: flex;
			align-items: center;
			color: transparent;
			height: 48px;
			&:before  {
				content: "Период";
				color: #111827;
				white-space: nowrap;
				font-size: 16px;
				font-style: normal;
				font-weight: 700;
				line-height: 24px; 
			}
		}
	}

	.rs-picker-daterange-predefined{
		padding: 0px 16px 0px 24px !important;
		display: flex !important;
		flex-direction: column !important;
		gap: 4px !important;
		height: 312px !important;
		@media ${breakpoints.tablet} {
      		display: none !important;
    	}
		.rs-stack-item{
			width: 100%;
			.rs-btn-link {
				text-decoration: none;
				color: ${({ theme }) => theme.text.black};
				padding: 8px;
				text-align: left;
				width: 100%;
				height: 40px;
				border-radius: 10px;
				&:hover:not(.rs-btn-disabled) {
					border-radius: 10px;
					background: ${({ theme }) => theme.lilac};
				}
			}
		}
	}
	
	.rs-calendar-time-view .rs-calendar-header-title-time{
		color:  ${({ theme }) => theme.main_violet} !important;
	}
	.rs-calendar-time-dropdown {
		padding-left: 0px !important;
		padding-right: 24px !important;
	}
	.rs-calendar-time-dropdown-cell {
		color: ${({ theme }) => theme.text.black} !important;
		&:hover:not(.rs-picker-disabled, .rs-calendar-time-dropdown-cell-active){
			background-color: ${({ theme }) => theme.lilac_light} ;
			color: ${({ theme }) => theme.text.black} ;
		}
		
	}
	.rs-calendar-time-dropdown-cell.rs-calendar-time-dropdown-cell-active {
		background-color: ${({ theme }) => theme.main_violet} !important;
		color: ${({ theme }) => theme.text.white} !important;
	}
	
	
	.rs-calendar-header-error, .rs-calendar-month-view .rs-calendar-header-title-date.rs-calendar-header-error {
		color: #575757;
		&:hover {
			background-color: #e5e5ea;
			color: #575757;
		}
		&:active , &:focus{
			background-color: transparent;
			color: ${({ theme }) => theme.main_violet} !important;
		}
	}
	
	
	
	.rs-calendar-table-cell-content{
		text-decoration: none !important;
		padding: 1px 2px 0px 0px !important;
	}
	s
	.rs-calendar-table-cell-selected .rs-calendar-table-cell-content{
		background-color: ${({ theme }) => theme.main_violet} !important;
		color:  ${({ theme }) => theme.text.white} !important;
		&:hover {
			background-color: ${({ theme }) => theme.main_violet} !important;
			color:  ${({ theme }) => theme.text.white} !important;
		}
	}
	.rs-picker-menu .rs-calendar .rs-calendar-table-cell:hover .rs-calendar-table-cell-content {
		background-color: ${({ theme }) => theme.lilac_light} !important;
		color:  ${({ theme }) => theme.text.black} !important;
	}
	.rs-picker-menu .rs-calendar .rs-calendar-table-cell-disabled:hover .rs-calendar-table-cell-content{
		background-color: ${({ theme }) => theme.white} !important;
		color: #c5c6c7 !important;
	}
	.rs-calendar-table-cell-is-today .rs-calendar-table-cell-content{
		box-shadow: inset 0 0 0 1px ${({ theme }) => theme.main_violet} !important;
	}
	.rs-picker-menu .rs-calendar .rs-calendar-month-dropdown-cell-content, .rs-picker-menu .rs-calendar .rs-calendar-table-cell-content{
		height: 24px !important;
		width: 24px !important;
		border-radius: 4px;
		transition-duration: 0.3s ease-in-out;
	
		@media ${breakpoints.tablet} {
			height: 32px !important;
			width: 32px !important;
			padding: 5px 5px !important;
			font-size: 16px !important;
		}
	}
	.rs-picker-menu .rs-calendar .rs-calendar-month-dropdown-cell-content {
		width: 100% !important;
		
		@media ${breakpoints.tablet} {
			height: 32px !important;
		}
	}

	.rs-calendar-table-header-cell{
		padding: 1px 2px;
		width: 24px !important;
		height: 24px !important;
		@media ${breakpoints.tablet} {
			width: 32px;
			height: 32px;
			padding: 4px;
		}
	
		background-color: transparent;
	}
	.rs-calendar-month-dropdown {
		@media ${breakpoints.tablet} {
			margin-top: 20px !important;
		}
		
	}
	
	.rs-calendar-month-dropdown-row {
     height: 120px !important;
    }
	.rs-calendar-month-dropdown-row:first-child {
     height: 120px !important;
    }
	.rs-calendar-month-dropdown-row:nth-child(2) {
	 top: 120px !important;
    }
	.rs-calendar-month-dropdown-row:nth-child(3) {
	 top: 240px !important;
    }
	.rs-picker-menu .rs-calendar .rs-calendar-time-dropdown-column > ul {
		${scrollbarStyles}
	}
	
	.rs-calendar-month-dropdown-row-wrapper{
		width: 210px !important;
		${scrollbarStyles}
		@media ${breakpoints.tablet} {
			width: 320px !important;
		}
	}
	.rs-calendar-month-dropdown-row-wrapper div:first-child {
		&:not(.rs-calendar-month-dropdown-cell, .rs-calendar-month-dropdown-row, .rs-calendar-month-dropdown-year) {
			height: 240px !important;
		}
	}

	.rs-calendar-table-cell-selected::before, .rs-calendar-table-cell-in-range::before{
		margin-top: 0px !important;
		@media ${breakpoints.tablet} {
			margin-top: 1px !important;
			margin-left: 4px !important;
		}
	}
	.rs-btn-primary.rs-btn-disabled, .rs-btn-primary:disabled {
		background-color: ${({ theme }) => theme.main_violet} !important;
	}
	.rs-calendar-table-cell-in-range:before, .rs-calendar-table-cell-selected:before {
		@media ${breakpoints.tablet} {
			width: 32px;
		}
		
	}

	.rs-btn-icon.rs-btn-xs {
		@media ${breakpoints.tablet} {
			padding: 0 !important;
			width: 24px !important;
			line-height: 24px !important;
			font-size: 24px !important;
			svg {
				font-size: 24px !important;
			}
		}
		
	}
	.rs-calendar-header-month-toolbar{
		@media ${breakpoints.tablet} {
			width: 100%;
			display: flex;
			margin: 10px 0px;
			justify-content: center;
		}
	}
	.rs-calendar-month-dropdown-content {
		width: 282px;
		height: 272px;
	}
	.rs-calendar-month-dropdown-scroll{
		@media ${breakpoints.tablet} {
			height: 100% !important;
		}
	}
	.rs-btn-xs{
		padding: 2px 6px;
		@media ${breakpoints.tablet} {
			font-size: 16px !important;
			font-style: normal;
			font-weight: 500;
			line-height: 20px !important;
		}
	}
	.rs-calendar-table-cell-in-range:not(.rs-calendar-table-cell-selected){
		border-radius: 4px;
		&:before {
			background-color: ${({ theme }) => theme.lilac} !important;
			border-radius: 4px;
			width: 24px !important;
			transition-duration: 0.3s ease-in-out;
			@media ${breakpoints.tablet} {
				width: 32px !important;
				height: 32px !important;
			}
		}
	}

	.rs-calendar-table-cell-selected .rs-calendar-table-cell-content{
		border-radius: 4px;
		background-color: ${({ theme }) => theme.main_violet} !important;
		&:before {
			background-color: ${({ theme }) => theme.main_violet} !important;
			border-radius: 4px;
			width: 24px !important;
			transition-duration: 0.3s ease-in-out;
			@media ${breakpoints.tablet} {
				width: 32px !important;
				height: 32px !important;
			}

		}
	}
	.rs-picker-menu .rs-calendar .rs-calendar-table-cell-selected:hover .rs-calendar-table-cell-content {
		background-color: ${({ theme }) => theme.main_violet} !important;
	}
	.rs-calendar-table-cell-selected:before {
		@media ${breakpoints.tablet} {
			width: 32px;
		}
	}
	

	

	.rs-picker-default:not(.rs-picker-disabled) .rs-btn:hover, .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover, .rs-picker-default:not(.rs-picker-disabled) .rs-btn:focus, .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:focus, .rs-picker-default:not(.rs-picker-disabled) .rs-btn-active, .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle-active{
		box-shadow: ${({ theme }) => `0px 4px 16px ${theme.lilac_light}`} !important;
		border-color: ${({ theme }) => theme.main_violet} !important;
		
	}

	
	
	
	.rs-calendar-table-cell-selected span{
		color:  ${({ theme }) => theme.text.white} !important;
	}
	.rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-clean, .rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-clean, .rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-caret, .rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-caret{
		top: 11px;
	}
	.rs-btn-close {
		font-size: 16px;
		color: #636366;
		&:hover{
			color: #111827 !important;
		}
	}
	

  .rs-picker-daterange-panel{
	font-family: ${notoSans.style.fontFamily} !important;
  }

	.rs-picker-toggle-clean svg path {
		stroke-width: 1;
		stroke: #636366 !important;
	}
	.rs-picker-toggle-clean:hover svg path {
		stroke: #111827 !important;
	}
	.rs-picker-has-value.rs-picker-cleanable .rs-picker-toggle.rs-btn {
		padding-right: 40px;
	}

	.ant-picker-presets {
		ul {
			padding: 16px 24px !important;
			li {
				font-weight: 400 !important;
				font-size: 14px !important;
				line-height: 20px !important;
				padding: 4px !important;
				&:hover {
					background:  ${({ theme }) => theme.lilac} !important;
				}
			}
		}
	}
	.rs-picker-toolbar{
		justify-content: flex-end !important;
		gap: 16px !important;
		@media ${breakpoints.tablet} {
			padding: 16px !important
		}
		.rs-btn {
			padding: 12px 24px !important;
			border-radius: 10px !important;
			background: #4F46E5;
			font-size: 16px !important;
			font-style: normal;
			font-weight: 500;
			line-height: 24px; 
			&:hover {
				background: #473ECF !important;
			}
			&:active{
				background: #3B34B8 !important;
			}
			@media ${breakpoints.tablet} {
				width: 100%;
			}
		}
		
		.rs-stack-item:first-child {
			.rs-btn {
				background: transparent !important;
				color: #4F46E5;
				border: 1px solid  #4F46E5;
				text-decoration: none;
				&:hover {
					background: #F3F3FF !important;
				}
				&:active{
					background: #E8E7FF !important;
				}
				@media ${breakpoints.tablet} {
				width: 100%;
			}
			}
		}
	}
	.rs-picker-daterange-menu .rs-picker-daterange-panel-show-one-calendar .rs-picker-toolbar-ranges{
		@media ${breakpoints.tablet} {
			width: 100% !important;
			max-width: 100% !important;
		}
	}
	.rs-stack-item {
		@media ${breakpoints.tablet} {
			width: 100% !important;
		}
		&:first-child {
			@media ${breakpoints.tablet} {
				width: 100% !important;
			}
		}
	}
	.rs-picker-daterange-menu .rs-picker-daterange-panel-show-one-calendar .rs-picker-toolbar {
		@media ${breakpoints.tablet} {
			max-width: 100% !important;
			width: 100% !important;
		}
	}
	.rs-calendar-header-time-toolbar{
		@media ${breakpoints.tablet} {
			display: none !important;
		}
	}

	.rs-calendar-month-dropdown-cell {
		width: 48px !important;
		span {
			width: 100% !important;
		}
		@media ${breakpoints.tablet} {
			width: 50px !important;
			
		}
	}


	.rs-picker-daterange-menu .rs-calendar-month-dropdown-list{
		width: 150px !important;
		min-width: 150px !important;
		@media ${breakpoints.tablet} {
			width: 100% !important;
		}
	}

	.rs-calendar-month-dropdown-cell-content {
		width: 100% !important;
		text-decoration: none !important;
		padding: 2px 4px !important;
		color: ${({ theme }) => theme.text.black};
		&:hover {
			background-color: ${({ theme }) => theme.lilac_light} !important;
		}
	}

	.rs-picker-menu .rs-calendar .rs-calendar-month-dropdown-cell:not(.disabled) .rs-calendar-month-dropdown-cell-content:hover{
		color: ${({ theme }) => theme.text.black}
	}
	.rs-picker-menu .rs-calendar .disabled  .rs-calendar-month-dropdown-cell-content:hover{
		color: #c5c6c7;
		background-color: transparent !important;

	}
	.rs-calendar-month-dropdown-cell-active .rs-calendar-month-dropdown-cell-content{
		background-color: ${({ theme }) => theme.main_violet} !important;	
		color: ${({ theme }) => theme.white} !important;

	}
	.rs-calendar-month-dropdown-year-active, .rs-calendar-month-view .rs-calendar-header-title-date{
		color: ${({ theme }) => theme.main_violet} !important;	
	}
	
	body .rs-picker-daterange-menu {
    z-index: 800;
    margin-top: 8px;
    @media ${breakpoints.tablet} {
      width: 100%;
      margin-top: 0px;
      height: 100%;
      bottom: 0px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      z-index: 11111 !important;
    }
  }
  .closeIcon {
	font-size: 35px;
	font-weight: 300;
	cursor: pointer;
	transform: rotate(45deg);
	color: #636366;
	&:hover {
		color: #111827;
	}
  }

  .tooltipIconContainer {
	position: relative;
	display: flex;
	align-items: center;
	&:hover {
		.tooltipWrapper {
			display: flex !important;
		}
  	}
  }


  .tooltipContainer {
	position: absolute;
	display: flex;
	align-items: center;
	height: 20px;
	width: fit-content;
	bottom: 32px;
	left: 32px;
	font-weight: 400;
	line-height: 16px; 
	font-size: 12px;
	gap: 5px;
	color: #636366;
	svg path {
		stroke: #636366 !important;
	}
  }
  .tooltipWrapper {
	display: none;
	position: absolute;
	top: -45px;
	font-size: 11px;
	width: 282px;
	height: 40px;
	padding: 4px 8px;
	border-radius: 6px;
	background: #484666;		
	color: #FFFFFF;
  }
	
	.ant-picker {
    	padding: 0 !important;
    	width: 100% !important;
  	}

  .ant-picker-active-bar {
    display: none;
  }
  .ant-picker-dropdown .ant-picker-cell {
    padding: 4px 4px !important;
  }
  .ant-picker-range-wrapper{
	position: absolute;
	left: -17px;
  }
  .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner {
    background-color: #4F46E5 !important;
		border-end-end-radius: 4px !important;
		border-top-left-radius: 4px !important;
		border-top-right-radius: 4px !important;
		border-bottom-right-radius: 4px !important;
		border-bottom-left-radius: 4px !important;
  }

  .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    background-color: #4F46E5 !important;
		border-end-end-radius: 4px !important;
		border-top-left-radius: 4px !important;
		border-top-right-radius: 4px !important;
		border-bottom-right-radius: 4px !important;
		border-bottom-left-radius: 4px !important;
  }

  .ant-picker-cell-in-view.ant-picker-cell-today
    .ant-picker-cell-inner::before {
    border-color: #4F46E5 !important;
  }

  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
  .ant-picker-cell-in-view.ant-picker-cell-in-range::before,
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before,
  .ant-picker-time-panel-column
    > li.ant-picker-time-panel-cell-selected
    .ant-picker-time-panel-cell-inner {
    	background: #E8E7FF !important;
			border-end-end-radius: 4px !important;
			border-top-left-radius: 4px !important;
			border-top-right-radius: 4px !important;
			border-bottom-right-radius: 4px !important;
			border-bottom-left-radius: 4px !important;
			/* max-width: 24px !important; */
			/* left: 6px; */
  }
	
	.ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-range-hover {
		.ant-picker-cell-inner {
			background: #E8E7FF !important;
			border-end-end-radius: 4px !important;
			border-top-left-radius: 4px !important;
			border-top-right-radius: 4px !important;
			border-bottom-right-radius: 4px !important;
			border-bottom-left-radius: 4px !important;
			/* max-width: 24px !important;
			max-height: 24px !important; */
		}
			
		::before {
			border: none !important;
		}
		::after {
			border: none !important;
		}
	}
	.ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-range-hover-end {
		.ant-picker-cell-inner {
			background: #E8E7FF;
			border-end-end-radius: 4px !important;
			border-top-left-radius: 4px !important;
			border-top-right-radius: 4px !important;
			border-bottom-right-radius: 4px !important;
			border-bottom-left-radius: 4px !important;
			/* max-width: 24px !important;
			max-height: 24px !important; */
		}
		::before {
			border: none !important;
		}
		::after {
			border: none !important;
		}
	}
	.ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-range-hover-start {
		.ant-picker-cell-inner {
			background: #E8E7FF;
			border-end-end-radius: 4px !important;
			border-top-left-radius: 4px !important;
			border-top-right-radius: 4px !important;
			border-bottom-right-radius: 4px !important;
			border-bottom-left-radius: 4px !important;
			/* max-width: 24px !important;
			max-height: 24px !important; */
		}
		::before {
			border: none !important;
		}
		::after {
			border: none !important;
		}
	}
  .ant-btn-primary {
    background-color: #27c456 !important;
    border-color: #1f9643e5 !important;
  }
	.ant-picker-clear {
    top: 6px !important;
		right: 30px !important;
  }

	//	Стили для редактора аватарки:
	.crop-point-container {
		width: 248px;
    height: 248px;
		background-color: transparent !important;
		z-index: 10;
		background-image: radial-gradient(circle at center, rgba(0, 0, 0, 0) 70%, rgba(0, 0, 0, 0.5) 71%);
	}
	.vignette-container {
		width: 248px;
    height: 248px;
		border: 1px solid #A4A4A5 !important;
		z-index: 11;
	}
	.crop-point {
		position: absolute;
		width: 8px;
		height: 8px;
		background: rgba(164, 164, 165, 0.88);
		z-index: 100;
	}
	.top-left {
		top: 52px;
		left: 52px;

		@media ${breakpoints.tablet} {
			top: 8px;
			left: 8px;

		}
	}
	.top-right {
		top: 52px;
		left: 300px;

		@media ${breakpoints.tablet} {
			top: 8px;
			left: 248px;

		}
	}
	.bottom-left {
		top: 300px;
		left: 52px;

		@media ${breakpoints.tablet} {
			top: 248px;
			left: 8px;

		}
	}
	.bottom-right {
		top: 300px;
		left: 300px;
		@media ${breakpoints.tablet} {
			top: 248px;
			left: 248px;

		}
	}
	.crop-container {
		top: 0px !important;
		left: 0px !important;
		height: 360px;
		width: 360px;

		@media ${breakpoints.tablet} {
			height: 264px;
			width: 264px;
		}
	}
	/* //	Скрытие дефолтного спиннера для прогресс-бара библиотеки nextjs-progressbar
	#nprogress .spinner {
  	display: none;
	} */
	/* Класс для уезжания вниз */

	.modal-slide-down {
  animation: slideDown 0.2s ease-in-out;
  transform: translateY(100%);
}

/* Класс для всплытия вверх */
.modal-slide-up {
  animation: slideUp 0.2s ease-in-out;
  transform: translateY(0);
}
.MuiModal-root {
	z-index: 800 !important;
}

@keyframes slideDown {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100%);
  }
}

@keyframes slideUp {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

`;

export default GlobalStyle;

export const COMMON_BOX_SHADOW = "0 4px 16px 0 rgba(0, 0, 0, 0.12)";
