import { useState } from "react";
import { NextPage } from "next";
import { Flex } from "@new-ui-kit";
import { CheckboxStyles } from "./styles";
import CheckboxIcon from "@assets/inputIcons/checkbox.svg";
import RadioIcon from "@assets/inputIcons/Radio.svg";
import { CheckboxPropsInterface } from "./types";
import { useDesktopMediaQuery, useTouchQuery } from "@helpers/hooks/apaptive";
import { RadioButtonLabel } from "../RadioButtons/styles";

const {
  Title,
  Label,
  Checkbox: CheckboxComponent,
  StyledCheckboxInput,
} = CheckboxStyles;

export const Checkbox: NextPage<CheckboxPropsInterface> = ({
  id,
  title,
  disabled = false,
  fullWidth,
  checked = false,
  setChecked,
  padding = "0px",
  margin = "0px",
  isRadio = false,
  labelWidth,
  isNotReversed = false,
}) => {
  const [focused, setFocused] = useState(false);
  const isTouchDevice = useTouchQuery();
  const isDesktop = useDesktopMediaQuery();

  const onFocus = () => {
    if (!isTouchDevice) setFocused(true);
  };
  const onBlur = () => {
    if (!isTouchDevice) setFocused(false);
  };

  const onChange = () => {
    if (!checked && isRadio) setChecked(true);
    if (!isRadio) setChecked(!checked);
  };

  return (
    <Label disabled={disabled} width={labelWidth}>
      <Flex
        flexDirection={isDesktop || isNotReversed ? "row" : "row-reverse"}
        width={fullWidth ? "100%" : "fit-content"}
        margin={margin}
        onFocus={onFocus}
        onMouseLeave={onBlur}
        padding={padding}
        justifyContent={isDesktop ? "flex-start" : "space-between"}
        gap={"10px"}
        pointer
      >
        <Flex width="fit-content" justifyContent="flex-end">
          <StyledCheckboxInput
            tabIndex={0}
            type="checkbox"
            id={id}
            name={id}
            checked={checked}
            disabled={disabled}
            onChange={onChange}
          />
          {!isRadio ? (
            <CheckboxComponent
              focused={focused}
              checked={checked}
              disabled={disabled}
            >
              {checked && (
                <Flex margin="4px 3px" pointer>
                  <CheckboxIcon
                    stroke={disabled ? "#A4A4A5" : "#FFFFFF"}
                    strokeWidth="2"
                  />
                </Flex>
              )}
            </CheckboxComponent>
          ) : (
            <RadioButtonLabel disabled={disabled}>
              {checked && <RadioIcon />}
            </RadioButtonLabel>
          )}
        </Flex>
        <Title disabled={disabled}>{title}</Title>
      </Flex>
    </Label>
  );
};
