import { Column, Flex, Row, Wrapper } from "@new-ui-kit";
import { InputOuter, Label, StyledInput } from "./styles";
import { IInputForLael } from "./types";
import { ChangeEvent, FC, useEffect, useState } from "react";

export const InputForLabels: FC<IInputForLael> = ({
  fullWidth,
  margin,
  startContent,
  zIndex,
  bottomTransform,
  customWidth,
  padding,
  disabled,
  customWidthOuter,
  id,
  setInputFocus,
  maxLength,
  labelTitle,
  autofocus,
  name,
  placeholder,
  value,
  readOnly,
  onChange = (event: ChangeEvent<HTMLInputElement>) => {},
  showDropdown,
}) => {
  const isLabel = !!labelTitle;
  const isLabelInput = true;
  const isInputTypeText = isLabelInput;

  const [focused, setFocused] = useState(false);

  const onFocus = () => setFocused(true);

  useEffect(() => {
    setInputFocus && setInputFocus(focused);
  }, [focused]);

  useEffect(() => {
    if (autofocus && id === "tableLabel") {
      const input = document.getElementById("tableLabel");
      input?.focus();
    }
  }, [autofocus, id]);

  return (
    <Column fullWidth={fullWidth} margin={margin ? margin : ""}>
      <InputOuter
        startContent={!!startContent}
        focused={focused || autofocus}
        disabled={disabled}
        isLabel={isLabel}
        fullWidth={fullWidth}
        isLabelInput={isLabelInput}
        customWidthOuter={customWidthOuter}
        showDropdown={showDropdown}
        bottomTransform={bottomTransform}
        zIndex={zIndex}
      >
        <Wrapper grab={isLabelInput}>
          <Flex>{startContent ? startContent : null}</Flex>
          <Column between={isLabel} centered={!isLabel} fullHeight fullWidth>
            {isLabel && <Label disabled={disabled}>{labelTitle}</Label>}
            <Row fullWidth>
              <StyledInput
                id={id ? id : ""}
                customWidth={customWidth}
                maxLength={maxLength}
                onChange={onChange}
                value={value}
                autoFocus={autofocus}
                name={name}
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
                readOnly={readOnly || !isInputTypeText}
                disabled={disabled}
                placeholder={placeholder ? placeholder : ""}
                onClick={() => onFocus()}
                type="text"
                padding={padding}
              />
            </Row>
          </Column>
        </Wrapper>
      </InputOuter>
    </Column>
  );
};
