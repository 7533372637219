import breakpoints from "@styles/breakpoints";
import useMediaQuery from "@mui/material/useMediaQuery";
// Adaptive Hooks
export const useDesktopMediaQuery = () => useMediaQuery(breakpoints.desktop);

export const useTabletAndBelowMediaQuery = () =>
  useMediaQuery(breakpoints.tablet);

export const useMobileMediaQuery = () => useMediaQuery(breakpoints.mobile);

export const useTabletMediaQuery = () =>
  useMediaQuery(`(min-width: 767px) and ${breakpoints.tablet}`);

export const useSmallMobileMediaQuery = () =>
  useMediaQuery(breakpoints.smallMobile);

export const useSmallHeightDisplay = () => useMediaQuery("(max-height:900px");

export const useTouchQuery = () => useMediaQuery("(hover: none)");
