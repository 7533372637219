import { styled } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel, {
  FormControlLabelProps,
} from "@mui/material/FormControlLabel";
import { SwitchProps } from "@mui/material/Switch";
import { Switch as SwitchBase } from "@mui/material";
import {
  Text,
  Flex,
  Tooltip,
  TTooltipPosition,
  IconComponent,
} from "@new-ui-kit";
import { Dispatch, FC, SetStateAction, SyntheticEvent } from "react";

import questionIcon from "@assets/question.svg";

import breakpoints from "@styles/breakpoints";

const StyledSwitchBase = styled((props: SwitchProps) => (
  <SwitchBase
    focusVisibleClassName=".Mui-focusVisible"
    disableRipple
    {...props}
  />
))(({ theme }) => ({
  width: 48,
  height: 24,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(24px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#4F46E5",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: "#FFFFFF",
      boxShadow: "-1px 0px 4px rgba(0, 0, 0, 0.0)",
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: 1,
      backgroundColor: "#E4E4E7",
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 20,
    height: 20,
    boxShadow: "-1px 0px 4px rgba(0, 0, 0, 0.25)",
  },
  "& .MuiSwitch-track": {
    borderRadius: 12,
    backgroundColor: "#A4A4A5",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const StyledFormGroup = styled(FormGroup)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  @media ${breakpoints.tablet} {
    justify-content: space-between;
  }
`;

const StyledFormControlLabel = styled(
  (props: FormControlLabelProps & { isDesktop?: boolean }) => (
    <FormControlLabel {...props} />
  )
)({
  margin: 0,
});

export interface ISwitchProps {
  title?: string;
  disabled?: boolean;
  checked: boolean;
  setChecked: Dispatch<SetStateAction<boolean>>;
  tooltip?: string | null;
  tooltipPosition?: TTooltipPosition;
  tooltipWidth?: string;
  id?: string;
}
export const Switch: FC<ISwitchProps> = ({
  title,
  checked,
  setChecked,
  disabled = false,
  tooltip = null,
  tooltipPosition,
  tooltipWidth,
  id,
}) => {
  const onChange = (
    event: SyntheticEvent<Element, Event>,
    checked: boolean
  ) => {
    setChecked(checked);
  };
  return (
    <StyledFormGroup>
      {!!title && tooltip !== null ? (
        <Flex margin="0 8px 0 0">
          <Text pattern="headline4" margin="0 8px 2px 0" pointer>
            {title}
          </Text>
          <Tooltip
            id="question"
            position={tooltipPosition || "bottom-right"}
            title={tooltip}
            nowrap={false}
            width={tooltipWidth}
          >
            <IconComponent Icon={questionIcon} />
          </Tooltip>
        </Flex>
      ) : !!title ? (
        <Text pattern="headline4" margin="0 8px 2px 0" pointer>
          {title}
        </Text>
      ) : (
        <></>
      )}
      <StyledFormControlLabel
        labelPlacement="start"
        control={<StyledSwitchBase aria-label={id} id={id} />}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        label={<></>}
      />
    </StyledFormGroup>
  );
};
