import breakpoints from "@styles/breakpoints";
import styled, { css } from "styled-components";
import Modal from "@mui/material/Modal";
import { scrollbarStyles } from "@styles/GlobalStyle";

const StyledModal = styled(Modal)<{ bottom?: string }>`
  bottom: ${({ bottom }) => (bottom ? bottom : "0px")} !important;

  .MuiModal-backdrop {
    bottom: ${({ bottom }) => (bottom ? bottom : "0px")} !important;
    background: rgba(0, 0, 0, 0.25);
  }
`;

const ModalWrapper = styled.div<{ bottom?: string }>`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: ${({ bottom }) => (bottom ? bottom : "0px")};
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
`;

const SidebarModalWrapper = styled.div<{ bottom?: string }>`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: ${({ bottom }) => (bottom ? bottom : "0px")};
  background: rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: 0.3s ease-out;
  z-index: -1;
  pointer-events: none;
  &.open {
    transition: 0.3s opacity;
    opacity: 1;
    z-index: 800;
    pointer-events: auto;
  }
`;

const ModalContent = styled.div<{
  nopadding?: boolean;
  scrollable?: boolean;
  big?: boolean;
  small?: boolean;
  isTabletModal: boolean;
}>`
  background-color: ${({ theme }) => theme.bg.main};
  border-radius: 10px;
  /* max-width: ${(props) => (props.big ? "1160px" : "566px")}; */
  /* height: ${(props) =>
    props.small ? "fit-content" : props.big ? "70vh" : "50vh"}; */
  position: absolute;
  top: ${({ isTabletModal }) => (isTabletModal ? "auto" : "50%")};
  bottom: ${({ isTabletModal }) => (isTabletModal ? "0px" : "auto")};
  left: ${({ isTabletModal }) => (isTabletModal ? "0px" : "50%")};
  transform: ${({ isTabletModal }) =>
    isTabletModal ? "none" : "translate(-50%, -50%)"};
  /* overflow-x: hidden;
  overflow-y: ${({ scrollable }) => (scrollable ? "auto" : "visible")}; */
  /* ${({ big }) =>
    big &&
    css`
      max-width: 1200px;
    `} */
  ${scrollbarStyles}
  &:focus-visible {
    outline: none;
  }

  @media ${breakpoints.tablet} {
    max-width: 520px;
  }

  @media ${breakpoints.mobile} {
    /* width: 92%;
    max-width: 520px; */
    width: 343px;
  }
`;

const ModalClose = styled.button`
  position: absolute;
  right: 32px;
  top: 24px;
  @media ${breakpoints.tablet} {
    right: 16px;
    top: 16px;
  }
`;

const ModalCloseWrapper = styled.div`
  position: sticky;
  top: 0px;
  z-index: 50;
`;

export const modalStyles = {
  ModalContent,
  ModalClose,
  ModalWrapper,
  ModalCloseWrapper,
  SidebarModalWrapper,
  StyledModal,
};
