import {
  TextLineHeight,
  TextSize,
  TextSizeMobile,
  TextWeight,
  TextWeightMobile,
} from "@new-ui-kit";
import styled, { css } from "styled-components";
import { IText } from "./types";
import breakpoints from "@styles/breakpoints";

export const Text = styled.div<IText>`
  width: ${({ width }) => (width ? width : "auto")};
  cursor: ${({ pointer, grab }) =>
    pointer ? "pointer" : grab ? "grab" : "default"};
  font-size: ${({ pattern, fontSize }) =>
    fontSize ? fontSize : pattern ? TextSize[pattern] : TextSize.body1}px;
  font-weight: ${({ pattern, weight }) =>
    weight ? weight : pattern ? TextWeight[pattern] : TextWeight.body1};
  line-height: ${({ pattern, lineHeight }) =>
    lineHeight
      ? lineHeight
      : pattern
      ? TextLineHeight[pattern]
      : TextLineHeight.headline1};
  color: ${({ color, theme, customColor }) =>
    customColor ? customColor : color ? theme.text[color] : theme.text.black};
  margin: ${({ margin = 0 }) => margin};
  text-align: ${({ centered, justify, rightAlign }) =>
    centered ? "center" : justify ? "justify" : rightAlign ? "right" : "left"};
  white-space: ${({ nowrap, multiline }) =>
    nowrap ? "nowrap" : multiline ? "pre-line" : "unset"};
  overflow: ${({ nowrap, ellipsis }) =>
    nowrap || ellipsis ? "hidden" : "unset"};
  text-overflow: ${({ nowrap, ellipsis }) =>
    nowrap || ellipsis ? "ellipsis" : "unset"};
  padding: ${({ padding }) => (padding ? `${padding}` : "0px")};
  user-select: ${({ userSelect }) => userSelect};
  ${({ isOneLine }) =>
    isOneLine &&
    css`
      white-space: nowrap;
    `};
  cursor: inherit;
  &:hover {
    text-decoration: ${({ underline }) => (underline ? "underline" : "none")};
  }
  b {
    font-weight: 500;
  }
  @media ${breakpoints.tablet} {
    font-size: ${({ pattern, fontSize }) =>
      fontSize
        ? fontSize
        : pattern
        ? TextSizeMobile[pattern]
        : TextSizeMobile.body1}px;

    font-weight: ${({ pattern, weight }) =>
      weight
        ? weight
        : pattern
        ? TextWeightMobile[pattern]
        : TextWeightMobile.body1};
  }
`;

export const UnselectedText = styled(Text)<{ pointer?: boolean }>`
  user-select: none;
  cursor: ${({ pointer }) => (pointer ? "pointer" : "default")};
  @media ${breakpoints.tablet} {
    font-size: 14px;
  }
`;
