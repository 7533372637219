import { createApi } from "@reduxjs/toolkit/query/react";
import { HYDRATE } from "next-redux-wrapper";
import { baseQueries } from "@features/baseQueries";
import { formatQueryAsArray, formatStrictNameString } from "@features/config";
import {
  IAgentsResponce,
  IAgentsRequest,
  IStatusRequest,
  ILabelToAgentsRequest,
  CallRecordResponseInterface,
  CallAgentRequest,
  GetAgentsQueueResults,
  GetAgentsLogsResults,
  Color,
  IAgentsResults,
  IAgentDataRequest,
  getAgentEnterInterface,
  AgentsStatusesRequest,
} from "@features/types";

const { fetchBaseQueryWithRefresh } = baseQueries;

export const agentsApi = createApi({
  reducerPath: "agentsApi",
  baseQuery: fetchBaseQueryWithRefresh,
  extractRehydrationInfo(action, { reducerPath }) {
    if (action.type === HYDRATE) {
      return action.payload[reducerPath];
    }
  },
  endpoints: (builder) => ({
    getAgents: builder.query<
      IAgentsResponce<Array<IAgentsResults>>,
      IAgentsRequest
    >({
      query(credentials) {
        return `/api/agents/${formatStrictNameString({ ...credentials })}`;
      },
    }),
    enterAgent: builder.query<getAgentEnterInterface, string>({
      query(agent_uuid) {
        if (agent_uuid) {
          return `/api/agents/${agent_uuid}/`;
        }
        return "";
      },
    }),
    getAgentStatus: builder.query<IStatusRequest, string>({
      query(link) {
        return `${link}`;
      },
    }),
    getColors: builder.query<Array<Color>, {}>({
      query() {
        return `/api/colors/`;
      },
    }),
    getCallResults: builder.query<{ call_result: string[] }, string>({
      query(agent_uuid) {
        return `/api/agents/${agent_uuid}/logs/call_results/`;
      },
    }),
    addLabelToAgent: builder.mutation<
      ILabelToAgentsRequest,
      ILabelToAgentsRequest
    >({
      query: ({ agent, labels }) => {
        return {
          url: `/api/agents/${agent}/labels/`,
          method: "POST",
          body: {
            labels: labels,
          },
        };
      },
    }),

    deleteLabelToAgent: builder.mutation<void, ILabelToAgentsRequest>({
      query: ({ agent, labels }) => {
        return {
          url: `/api/agents/${agent}/labels/`,
          method: "DELETE",
          body: {
            labels,
          },
        };
      },
    }),

    getAgentData: builder.query<
      IAgentsResponce<Array<GetAgentsLogsResults>>,
      IAgentDataRequest
    >({
      query({ agent_uuid, search: msisdn, filters, ...credentials }) {
        return `/api/agents/${agent_uuid}/logs/${formatQueryAsArray({
          msisdn: msisdn ? [msisdn] : [],
          ...filters,
          ...credentials,
        })}`;
      },
    }),
    getAgentSession: builder.query<
      IAgentsResponce<Array<GetAgentsQueueResults>>,
      AgentsStatusesRequest
    >({
      query({ ordering, agent_uuid, page, page_size, search }) {
        return `/api/agents/${agent_uuid}/queue/${formatStrictNameString({
          ordering,
          page,
          page_size,
          search,
        })}`;
      },
    }),
    getCallRecord: builder.query<CallRecordResponseInterface, CallAgentRequest>(
      {
        query({ agent_uuid, id }) {
          return `/api/agents/${agent_uuid}/logs/${id}/call_record/`;
        },
      }
    ),
  }),
});

export const {
  useGetCallResultsQuery,
  useEnterAgentQuery,
  useGetAgentsQuery,
  useGetAgentStatusQuery,
  useGetColorsQuery,
  useAddLabelToAgentMutation,
  useDeleteLabelToAgentMutation,
  useGetAgentDataQuery,
  useGetAgentSessionQuery,
  useGetCallRecordQuery,
} = agentsApi;
