import styled from "styled-components";

export const DropdownStyledItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  text-align: start;
  width: 100%;
  cursor: pointer;
  transition: 0.2s ease-out;
  &:hover {
    transition: 0.2s ease-out;
    background: ${({ theme }) => theme.lilac_light};
  }
`;
