import { InputStateType, stateFormatterProps } from "./types";

const stateFormatter = ({
  disabled,
  focused,
  hover,
  filled,
  noFocus,
  noHover,
  errorDefault,
  validation,
  success,
}: stateFormatterProps) => {
  let state: InputStateType;

  if (disabled) {
    state = "disabled";
  } else if (errorDefault) {
    state = "errorDefault";
  } else if (validation) {
    state = "validation";
  } else if (success) {
    state = "success";
  } else if (focused && !noFocus) {
    state = "focused";
  } else if (filled) {
    state = "filled";
  } else if (hover && !noHover && !filled) {
    state = "hover";
  } else if (hover && !noHover && filled) {
    state = "filledWithHover";
  } else {
    state = "default";
  }

  return state;
};

export const inputConfig = { stateFormatter };
