import styled, { css } from "styled-components";
import { IItem, IRadioButton, IRadioButtonLabel, IWrapper } from "./types";

export const RadioButtonLabel = styled.label<IRadioButtonLabel>`
  position: absolute;
  cursor: pointer;
  pointer-events: none;
  min-width: ${({ small }) => (small ? "12px" : "24px")};
  min-height: ${({ small }) => (small ? "12px" : "24px")};
  width: ${({ small, asButton, labelWidth }) =>
    labelWidth || (asButton ? "200px" : small ? "12px" : "24px")};
  height: ${({ small, asButton, labelWidth }) =>
    labelWidth || (asButton ? "50px" : small ? "12px" : "24px")};
  border-radius: ${({ asButton }) => (asButton ? "8px" : "50%")};
  background: white;
  border: ${({ theme }) => `1px solid  ${theme.border.sidebar}`};
  ${({ theme, ...props }) =>
    props.disabled &&
    css`
      border-color: ${({ theme }) =>
        `1px solid  ${theme.btn.primary.disabled}`};
    `}
`;

export const Circle = styled.div`
  display: none;
  position: relative;
  min-width: 12px;
  left: -18px;
  min-height: 12px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: ${({ theme }) => `${theme.main_violet}`};
  z-index: 15;
`;

export const RadioButton = styled.input<IRadioButton>`
  cursor: ${({ disabled }) => (disabled ? "" : "pointer")};
  opacity: 0;
  z-index: 1;
  border-radius: 8px;
  min-width: ${({ small, asButton, labelWidth }) =>
    labelWidth || (asButton ? "200px" : small ? "12px" : "24px")};
  min-height: ${({ small, asButton }) =>
    asButton ? "50px" : small ? "12px" : "24px"};
  /* margin-right: ${({ labelWidth }) => (labelWidth ? 0 : "8px")}; */
  margin-right: 0;

  ${({ theme, ...props }) =>
    !props.disabled &&
    css`
      &:hover ~ ${RadioButtonLabel} {
      
        border: ${({ theme }) => `1px solid  ${theme.main_violet}`};
        border-radius: 100%
        border:none;
        &::after {
          content: '';
          display: block;
          border-radius: 100%
          width: ${props.small ? "12px" : "24px"};
          height: ${props.small ? "12px" : "24px"};
          margin: -1px;
        }
      }
    `}

  ${({ theme, ...props }) =>
    (props.checked &&
      !props.disabled &&
      css`
        &:checked ~ ${RadioButtonLabel} {
          background: ${theme.bg.cards};
          border: ${({ theme }) => `1px solid  ${theme.main_violet}`};
          border-radius: 100%
          &::after {
            content: '';
            display: block;
            border-radius: 100%
            width: ${props.small ? "12px" : "24px"};
            height: ${props.small ? "12px" : "24px"};
            margin: -1px;
            background: ${!props.disabled ? "purple" : theme.lilac_light};
            border: 1px solid ${!props.disabled ? "#3258FB" : "transparent"};
          }
        }
        &:checked + ${Circle} {
          display: flex;
        }
      `) ||
    css`
      margin-right: 12px;
    ` ||
    (props.small &&
      css`
        &:checked + ${RadioButtonLabel} {
          &::after {
            width: 12px;
            height: 12px;
          }
        }
      `)}

    ${({ asButton, checked }) =>
    asButton &&
    css`
      & ~ ${RadioButtonLabel} {
        width: 100%;
        border: none;
      }

      &::after {
        border-radius: 8px;
        overflow: hidden;
        width: 200px;
        height: 50px;
        border: none !important;
        overflow: hidden;
        content: " ";
      }

      &:hover ~ ${RadioButtonLabel} {
        color: white !important;
        &::after {
          border-radius: 8px;
          width: 200px;
          height: 50px;
          border: none;
          background: ${({ theme }) => theme.main_violet};
          opacity: 0.02;
        }
      }

      ${checked &&
      css`
        &:checked + ${RadioButtonLabel} {
          border: none;
          background: ${({ theme }) => theme.main_violet};
          &::after {
            border-radius: 8px;
            color: white;
            opacity: 0.02;
            height: 50px;
          }
        }
      `}
    `}
`;

export const Item = styled.label<IItem>`
  display: flex;
  align-items: center;
  overflow: hidden;
  /* height: 50px; */
  /* padding: ${(props) => (props.noPadding ? 0 : "16px 0")}; */
  font-size: 15px;
  ${({ small }) =>
    small &&
    css`
      /* padding: 5px 8px 5px 8px; */
    `}
  position: relative;
  top: ${({ inTableIndex }) =>
    inTableIndex ? `${50 * inTableIndex}px` : "auto"};
  cursor: ${({ disabled }) => (disabled ? "" : "pointer")};
  ${({ asRadio, checked }) =>
    !asRadio &&
    css`
      background: ${({ theme }) => (checked ? theme.lilac : theme.bg.main)};
      /* padding: 16px 18px; */
      ${RadioButton} {
        display: none;
        opacity: 0;
      }
      ${RadioButtonLabel} {
        display: none;
        opacity: 0;
      }
    `};
  &:hover {
    background: ${({ disabled, tips, asRadio, theme }) =>
      !disabled && !tips && !asRadio && "red"};
    ${RadioButtonLabel} {
      ${(props) =>
        !props.asRadio &&
        css`
          &::after {
            content: "";
            display: block;
            border-radius: 50%;
            width: ${props.small ? "8px" : "12px"};
            height: ${props.small ? "8px" : "12px"};
            margin: 5px;
            background: #eeeeee;
          }
        `};
    }
  }
`;

export const Wrapper = styled.div<IWrapper>`
  height: ${({ wrapperHeight }) =>
    wrapperHeight ? `${wrapperHeight}px` : "auto"};
  width: 100%;
  border-radius: 10px;
  box-sizing: border-box;
  ${({ small }) =>
    small &&
    css`
      min-width: 117px;
    `};
  ${(props) =>
    props.row &&
    css`
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      /* margin: ${props.noVerticalMargin
        ? "0 10px 0 0"
        : "10px 10px 10px 0"}; */
      gap: ${() => (props.labelWidth ? 0 : "20px")};
    `}
  ${({ wrapperHeight }) =>
    wrapperHeight &&
    css`
      ${Item} {
        position: absolute;
      }
    `}
`;
