import breakpoints from "@styles/breakpoints";
import styled, { css } from "styled-components";

type DisabledType = { disabled: boolean };
type CheckboxProps = {
  focused: boolean;
  checked: boolean;
  disabled: boolean;
};

const Title = styled.div<DisabledType>`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ theme, disabled }) => (disabled ? "#a4a4a5" : theme.text.black)};
`;

const Label = styled.label<DisabledType & { width?: string }>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  width: ${({ width }) => (width ? width : "100%")};
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
`;

const Checkbox = styled.div<CheckboxProps>`
  cursor: pointer;
  position: relative;
  height: 24px !important;
  width: 24px !important;
  min-width: 24px !important;
  border-radius: 4px;
  transition: 0.2s ease-out;

  border: ${({ theme, checked, focused, disabled }) =>
    `1px solid ${
      checked || focused ? theme.main_violet : theme.stroke.default
    }`};

  background-color: ${({ theme, checked }) =>
    checked ? theme.lilac_light : "transparent"};
  box-shadow: ${({ focused }) =>
    focused ? "0px 0px 5px rgba(79, 70, 229, 0.5)" : "none"};
  ${Label}:hover & {
    ${({ checked, theme, disabled }) =>
      !checked &&
      css`
        background-color: ${disabled
          ? theme.stroke.default
          : theme.lilac_light};
        border: 1px solid ${({ theme }) => theme.stroke.hover};
      `}
  }
  @media ${breakpoints.tablet} {
    border: ${({ theme, checked, focused }) =>
      `1px solid ${
        checked || focused ? theme.main_violet : theme.stroke.default
      }`};
    background-color: ${({ theme, checked }) =>
      checked ? theme.lilac_light : "transparent"};
    box-shadow: none;

    ${Label}:hover & {
      ${({ checked, theme, disabled }) =>
        !checked &&
        css`
          background-color: ${disabled ? theme.stroke.default : theme.bg.cards};
          border: 1px solid ${({ theme }) => theme.stroke.default};
        `}
    }
  }
`;

const StyledCheckboxInput = styled.input`
  opacity: 0;
  width: 0;
  &:-webkit-autofill,
  &:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
  }
  &:checked ~ ${Checkbox} {
    opacity: 1;
    border: 1px solid transparent;
    background-color: ${({ theme }) => theme.main_violet};
  }
  &:disabled ~ ${Checkbox} {
    border: 1px solid transparent;
    background-color: ${({ theme }) => theme.btn.primary.disabled.bg};
  }
`;

export const CheckboxStyles = {
  Title,
  Label,
  Checkbox,
  StyledCheckboxInput,
};
